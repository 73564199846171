import { HubApiRepository } from "@/app/infrastructures/repositories/api/HubApiRepository";
import { Hub, HubList, RequestHubList } from "@/domain/entities/Hub";
import { injectable } from "tsyringe";


@injectable()
export class HubPresenter {
    private repository: HubApiRepository;

    constructor(repository: HubApiRepository) {
        this.repository = repository;
    }

    public getHubList(
        params: RequestHubList
    ): Promise<HubList> {
        return this.repository.getHubList(params);
    }

    public getHubListByBaggingGroup(): Promise<Hub[]> {
        return this.repository.getHubListByBaggingGroup();
    }
}