import {
  ResponseUpdate,
  CustomProcessSttData,
  CustomProcessSttDetail,
  CustomProcessPieceDetail,
  CustomProcessReason,
  CustomProcessReasonList,
  SttFailedDetail,
  ReverseDestinationData,
} from "@/domain/entities/CustomProcess";
import { AxiosResponse } from "axios";
import {
  CustomProcessEntities,
  CustomProcessData,
  DetailCustomProcess,
  STT,
} from "@/domain/entities/CustomProcess";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

export class CustomProcessMapper {
  public convertCustomProcessListDataFromApi(
    res: AxiosResponse<any>
  ): CustomProcessEntities {
    const { data } = res;

    const customProcess: CustomProcessData[] = [];
    if (data.data.length === 0) {
      return new CustomProcessEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        customProcess.push(
          new CustomProcessData({
            id: item.custom_process_id,
            totalStt: item.custom_process_total_stt,
            totalPiece: item.custom_process_total_piece,
            latestStatus: item.custom_process_latest_status,
            createdAt: item.custom_process_created_at,
            createdBy: item.custom_process_created_name,
            updatedAt: item.custom_process_updated_at,
            updatedBy: item.custom_process_updated_name,
            partnerName: item.custom_process_partner_name,
            partnerCode: item.custom_process_partner_code,
            remarks: item.custom_process_remarks
              ? item.custom_process_remarks
              : "-",
          })
        );
      });
    }
    return new CustomProcessEntities(
      new Pagination(data.meta.page, data.meta.limit),
      customProcess
    );
  }
  public convertUpdateStatusFromApi(
    result: AxiosResponse<any>
  ): ResponseUpdate {
    const {
      data: { data },
    } = result;

    let stt: string[] = [];
    if (data.stt_success_ref_no) {
      stt = data.stt_success_ref_no[0].split(",");
    }

    return new ResponseUpdate({
      customProcessId: data.custom_process_id,
      totalSttSuccess: data.total_stt_success,
      totalSttFailed: data.total_stt_failed,
      sttFailed: data.stt_failed.length
        ? data.stt_failed.map((item: any) =>
            typeof item === "object"
              ? new SttFailedDetail({
                  sttNo: item.stt_no,
                  bagNo: item.bag_no,
                  bagVendorNo: item.bag_vendor_no,
                  error: item.error,
                })
              : item
          )
        : [],
      newSttNo: stt[1],
      sttId: Number(stt[2]),
    });
  }

  public convertSttDataFromApi(
    result: AxiosResponse<any>
  ): CustomProcessSttData {
    const { data: { data } } = result;
    return new CustomProcessSttData({
      isAllowUpdateStatus: data.is_allow_update_status,
      errorMessage: data.error_message,
      stt: data.stt ? data.stt.map((item: any) => new CustomProcessSttDetail({
                sttId: item.stt_id,
                sttNo: item.stt_no,
                bagNo: item.bag_no,
                demNo: item.dem_no,
                sttProductType: item.stt_product_type,
                sttTotalPiece: item.stt_total_piece,
                sttDestinationCityId: item.stt_destination_city_id,
                sttDestinationCityName: item.stt_destination_city_name,
                sttOriginCityId: item.stt_origin_city_id,
                sttOriginCityName: item.stt_origin_city_name,
                sttWoodPacking: item.stt_wood_packing,
                sttCommodityCode: item.stt_commodity_code,
                sttCommodityName: item.stt_commodity_name,
                sttGrossWeight: item.stt_gross_weight,
                sttVolumeWeight: item.stt_volume_weight,
                sttChargeableWeight: item.stt_chargeable_weight,
                sttCodAmount: item.stt_cod_amount,
                sttLastStatusId: item.stt_last_status_id,
                posName: item.pos_name,
                bookedAt: item.booked_at,
                piece: item.piece.map((item: any) => new CustomProcessPieceDetail({
                      sttPieceId: item.stt_piece_id,
                      sttPieceGrossWeight: item.stt_piece_gross_weight,
                      sttPieceVolumeWeight: item.stt_piece_volume_weight,
                      sttPieceNo: item.stt_piece_no,
                      sttPieceLastStatusId: item.stt_piece_last_status_id,
                    })
                ),
                sttElexysNo: item.stt_elexys_no,
                sttReturnLastStatus: item.stt_return_last_status,
                sttReturnCityCode: item.stt_return_city_code,
                sttReturnCityName: item.stt_return_city_name,
                sttReturnDistrictCode: item.stt_return_district_code,
                sttReturnDistrictName: item.stt_return_district_name,
                sttReturnReceiptName: item.stt_return_receipt_name,
                sttReturnReceiptPhone: item.stt_return_receipt_phone,
                sttReturnReceiptAddress: item.stt_return_receipt_address,
                sttReturnReceiptAddressType: item.stt_return_receipt_address_type,
                isReturnToOriginAddress: item.is_return_to_origin_address,
                isUpdatedByInternal: item.is_updated_by_internal,
                reverseDestination: new ReverseDestinationData({
                  remarks: item.reverse_destination.remarks,
                  sttProductType: item.reverse_destination.stt_product_type,
                  returnCityCode: item.reverse_destination.return_city_code,
                  returnCityName: item.reverse_destination.return_city_name,
                  returnDistrictCode: item.reverse_destination.return_district_code,
                  returnDistrictName: item.reverse_destination.return_district_name,
                  returnReceiptAddress: item.reverse_destination.return_receipt_address,
                  returnReceiptAddressType: item.reverse_destination.return_receipt_address_type,
                  returnReceiptName: item.reverse_destination.return_receipt_name,
                  returnReceiptPhone: item.reverse_destination.return_receipt_phone,
                  sttDestinationZipCode: item.reverse_destination.stt_destination_zip_code,
                  sttCommodityCode: item.reverse_destination.stt_commodity_code,
                  sttGoodsStatus: item.reverse_destination.stt_goods_status,
                  sttTaxNumber: item.reverse_destination.stt_tax_number,
                  sttPiecePerPack: item.reverse_destination.stt_piece_per_pack,
                  sttNextCommodity: item.reverse_destination.stt_next_commodity,
              }),
              isOneBagScan: data.is_one_bag_scan
          })
        ) : [],
      isPaid: data.is_paid,
      isOneBagScan: data.is_one_bag_scan
    });
  }

  public convertCustomProcessDetailFromApi(
    res: AxiosResponse<any>
  ): DetailCustomProcess {
    const {
      data: { data },
    } = res;
    const sorted: any = data.stt.map((item: any) => {
      return item.piece.sort((a: any, b: any) => {
        return a.stt_piece_no - b.stt_piece_no;
      });
    });
    return new DetailCustomProcess({
      customProcessPartnerName: data.custom_process_partner_name,
      customProcessPartnerType: data.custom_process_partner_type,
      customProcessCreatedBy: data.custom_process_created_by,
      customProcessCreatedName: data.custom_process_created_name,
      customProcessCreatedAt: data.custom_process_created_at,
      customProcessLatestStatus: data.custom_process_latest_status,
      customProcessTotalStt: data.custom_process_total_stt,
      customProcessTotalPiece: data.custom_process_total_piece,
      customProcessTotalGrossWeight: data.custom_process_total_gross_weight,
      customProcessTotalVolumeWeight: data.custom_process_total_volume_weight,
      customProcessTotalChargeableWeight:
        data.custom_process_total_chargeable_weight,
      customProcessRemarks: data.custom_process_remarks,
      stt: data.stt.map(
        (item: any, index: any) =>
          new STT({
            sttId: item.stt_id,
            remarks: data.custom_process_remarks || "",
            sttNo: item.stt_no,
            sttReplacementNo: item.stt_no_ref_external,
            productType: item.stt_product_type,
            totalPieces: item.stt_total_piece,
            originCityId: item.stt_origin_city_id,
            originCityName: item.stt_origin_city_name,
            woodPacking: item.stt_wood_packing,
            destinationCityId: item.stt_destination_city_id,
            destinationCityName: item.stt_destination_city_name,
            commodityCode: item.stt_commodity_code,
            commodityName: item.stt_commodity_name,
            totalGrossWeight: item.stt_gross_weight,
            totalVolumeWeight: item.stt_volume_weight,
            chargeableWeight: item.stt_chargeable_weight,
            piece: sorted[index],
            sttElexysNo: item.stt_elexys_no || "",
          })
      ),
    });
  }

  public convertCustomProcessReasonFromApi(
    res: AxiosResponse<any>
  ): CustomProcessReasonList {
    const { data } = res;
    return new CustomProcessReasonList({
      data: data.data.map(
        (key: any) =>
          new CustomProcessReason({ name: key.status, value: key.status })
      ),
    });
  }

  
  public convertPatchReverseDestination(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = res;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      code: data.code
    });
  }
}
