import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import * as Rox from "rox-browser";

const isAssigned3lc = (value: {accountType: string, roleName: string}) => {
  Rox.setCustomStringProperty("data account type", function() {
    return value.accountType.toLowerCase();
  });
  Rox.setCustomStringProperty("data account role name", function() {
    return value.roleName.toLowerCase();
  });
  return FlagsMedusa.config_is_assigned_3lc.isEnabled();
};
export default isAssigned3lc;
