import {
  CustomerListEntities,
  Customer,
  EstimationPrice,
  EstimationCodRetail,
  PrintDataEntitas,
  ShipmentIdList,
  ShipmentId,
  ShipmentIdSTT,
  CustomerExist,
  ProductDelivery,
  ProductDeliveryData,
  InsuranceTypeDelivery,
  InsuranceTypeDeliveryData,
  EstimationServiceLevelAgreement,
  PosterProduct,
  checkEnableCommodity
} from "@/domain/entities/Booking";
import { DetailDiscount } from "@/domain/entities/Tariff";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  formatDateWithoutTime,
  IS_STT_RTS,
  removeNewLineWhitespace
} from "@/app/infrastructures/misc/Utils";
import { codePhoneCountry } from "@/app/ui/views/shipment/booking/booking-utils";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
  detectLanguage,
  JsPdfLanguage
} from "@/app/infrastructures/misc/common-library/DetectLanguage";

const extraReceipt = FlagsMedusa.config_extra_receipt.isEnabled();

const summaryPrintData = (data: any) => ({
  totalRates: data.stt_is_promo
    ? data.stt_shipping_surcharge_rate_after_discount +
      data.stt_publish_rate_after_discount +
      data.stt_destination_district_rate_after_discount +
      data.stt_origin_district_rate_after_discount
    : data.stt_shipping_surcharge_rate +
      data.stt_publish_rate +
      data.stt_destination_district_rate +
      data.stt_origin_district_rate,
  totalTariff: data.stt_is_promo
    ? data.stt_total_amount
    : data.stt_total_amount_before_discount
});

const registerFontJsPdfOnPrint = async (data: any) => {
  await Promise.all([
    detectLanguage(
      new JsPdfLanguage({
        text: data.stt_sender_name,
        product: data.stt_product_type
      })
    ),
    detectLanguage(
      new JsPdfLanguage({
        text: data.stt_recipient_name,
        product: data.stt_product_type
      })
    ),
    detectLanguage(
      new JsPdfLanguage({
        text: data.stt_recipient_address,
        product: data.stt_product_type
      })
    )
  ]);
};

export class BookingMapper {
  public convertCustomerDataFromApi(
    res: AxiosResponse<any>
  ): CustomerListEntities {
    const { data } = res;
    const customer: Customer[] = [];
    if (data.data.length === 0) {
      return new CustomerListEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        customer.push(
          new Customer(
            item.customer_id,
            item.customer_name,
            item.customer_phone_number,
            item.customer_address,
            item.customer_district_id,
            item.customer_district_name,
            item.customer_zip_code,
            item.customer_address_type,
            item.customer_commodity_code || ""
          )
        );
      });
    }
    return new CustomerListEntities(
      new Pagination(data.meta.page, data.meta.limit),
      customer
    );
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      sttNumber: data.data.stt[0].stt_no,
      sttId: data.data.stt[0].stt_id,
      isDiscount: data.data.stt[0].is_discount,
      totalDiscount: data.data.stt[0].total_discount,
      totalAfterDiscount: data.data.stt[0].total_after_discount,
      totalBeforeDiscount: data.data.stt[0].total_before_discount,
      chargeableWeight: data.data.stt[0].chargeable_weight
    });
  }

  public convertPayloadDataCustomer(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertEstimationPrice(res: AxiosResponse<any>): EstimationPrice {
    const { data } = res;
    return new EstimationPrice({
      cityRates: data.data.city_rates,
      forwardRates: data.data.forward_rates,
      chargeableWeight: data.data.chargeable_weight,
      shippingCost: data.data.shipping_cost,
      commoditySurcharge: data.data.commodity_surcharge,
      heavyWeightSurcharge: data.data.heavy_weight_surcharge,
      documentSurcharge: data.data.document_surcharge,
      insuranceRates: data.data.insurance_rates,
      insuranceName: data.data.insurance_name,
      insuranceLabel: data.data.insurance_label,
      woodpackingRates: data.data.woodpacking_rates,
      totalTariff: data.data.total_tariff,
      taxRates: data.data.tax_rates,
      bmTaxRate: data.data.bm_tax_rate,
      ppnTaxRate: data.data.ppn_tax_rate,
      pphTaxRate: data.data.pph_tax_rate,
      originDistrictRate: data.data.origin_district_rate,
      destinationDistrictRate: data.data.destination_district_rate,
      publishRate: data.data.publish_rate,
      shippingSurchargeRate: data.data.shipping_surcharge_rate,
      productType: data.data.product_type,
      totalTariffAfterDiscount: data.data.total_tariff_after_discount,
      totalDiscount: data.data.total_discount,
      isPromo: data.data.is_promo,
      discount: data.data.discount,
      codAmount: data.data.cod_amount,
      codFee: data.data.cod_fee,
      padFee: data.data.pad_fee,
      listDiscount: data.data.list_discount?.length
        ? data.data.list_discount.map((el: any) => {
            return new DetailDiscount({
              pdcName: el.config.pdc_name,
              pdcPromoDiscount: el.config.pdc_promo_discount,
              pdcCategory: el.config.pdc_category,
              pdcEndDate: el.config.pdc_end_date,
              pdcDiscountAmount: el.tariff.discount_amount
            });
          })
        : [],
      additionalCost:
        data.data.commodity_surcharge +
        data.data.heavy_weight_surcharge +
        data.data.document_surcharge +
        data.data.woodpacking_rates,
      insuranceAdminFee: data.data.insurance_admin_fee
    });
  }

  public convertEstimationPriceCodRetail(
    res: AxiosResponse<any>
  ): EstimationCodRetail {
    const { data } = res;
    return new EstimationCodRetail({
      returnFee: data.data.total_tariff > 0 ? data.data.total_tariff / 2 : 0
    });
  }

  public async convertPrintDataDataFromApi(
    result: AxiosResponse<any>
  ): Promise<PrintDataEntitas> {
    const {
      data: { data }
    } = result;
    const { totalRates, totalTariff } = summaryPrintData(data);
    const isInterpack = data.stt_product_type.toUpperCase() === "INTERPACK"
    if (isInterpack) {
      await registerFontJsPdfOnPrint(data);
    }
    return new PrintDataEntitas({
      sttId: data.stt_id,
      sttNo: data.stt_no,
      sttProductType:
        data.stt_product_type?.toUpperCase() === "JUMBOPACKH2H"
          ? "JUMBOPACK"
          : data.stt_product_type,
      sttCreatedAt: data.stt_created_at,
      sttOriginCityId: data.stt_origin_city_id,
      sttOriginCityName: data.stt_origin_city_name,
      sttOriginCityTransit: data.stt_origin_city_transit,
      sttDestinationCityId: data.stt_destination_city_id,
      sttDestinationCityTransit: data.stt_destination_city_transit,
      sttOriginDistrictId: data.stt_origin_district_id,
      sttDestinationDistrictId: data.stt_destination_district_id,
      sttDestinationDistrictName: data.stt_destination_district_name,
      sttDistrictUrsaCode: data.stt_district_ursa_code,
      sttDistrictType: data.stt_district_type,
      sttDestinationDistrictType: data.stt_destination_district_type,
      sttInformationDistrictType: data.stt_information_district_type,
      sttShipmentSource: data.stt_shipment_source,
      sttSenderName: data.stt_sender_name,
      sttSenderPhone: data.stt_sender_phone,
      sttSenderAddress: data.stt_origin_district_name,
      sttRecipientName: data.stt_recipient_name,
      sttRecipientAddress: data.stt_recipient_address,
      sttRecipientAddressType: data.stt_recipient_address_type,
      sttRecipientPhone: data.stt_recipient_phone,
      sttBookedName: data.stt_booked_name,
      sttNoRefExternal: data.stt_no_ref_external,
      sttCommodityCode: data.stt_commodity_code,
      sttCommodityName: data.stt_commodity_name,
      sttIsCod: data.stt_is_cod,
      sttIsDfod: data.stt_is_dfod,
      sttIsDo: data.stt_is_do,
      sttCodAmount: data.stt_cod_amount,
      sttTotalAmount: data.stt_total_amount_before_discount,
      sttBmTaxRate: data.stt_bm_tax_rate,
      sttPpnTaxRate: data.stt_ppn_tax_rate,
      sttPphTaxRate: data.stt_pph_tax_rate,
      sttShippingSurchargeRate: data.stt_shipping_surcharge_rate,
      sttPublishRate: data.stt_publish_rate,
      sttOriginDistrictRate: data.stt_origin_district_rate,
      sttDestinationDistrictRate: data.stt_destination_district_rate,
      sttDocumentSurchargeRate: data.stt_document_surcharge_rate,
      sttCommoditySurchargeRate: data.stt_commodity_surcharge_rate,
      sttHeavyweightSurchargeRate: data.stt_heavyweight_surcharge_rate,
      sttInsuranceRate: data.stt_is_promo
        ? data.stt_insurance_rate_after_discount
        : data.stt_insurance_rate,
      sttWoodpackingRate: data.stt_woodpacking_rate,
      sttGrossWeight: data.stt_gross_weight,
      sttVolumeWeight: data.stt_volume_weight,
      sttChargeableWeight: data.stt_chargeable_weight,
      sttTotalPiece: data.stt_total_piece,
      estimateSla: data.estimate_sla,
      sttTotalSurcharge: data.stt_total_surcharge,
      sttAmount: data.stt_total_amount_before_discount,
      sttPieces: extraReceipt
        ? data.stt_pieces.concat(data.stt_pieces[data.stt_pieces.length - 1])
        : data.stt_pieces,
      sttVendorCode: data.stt_vendor_code,
      sttVendorName: data.stt_vendor_name,
      sttShipmentId: data.stt_shipment_id,
      sttClientId: data.stt_client_id,
      sttClientName: data.stt_client_name,
      sttVendorRefferenceNo: data.stt_vendor_reference_no,
      sttCodFee: data.stt_is_promo
        ? data.stt_cod_fee_after_discount
        : data.stt_cod_fee,
      otherShipperTicketCode:
        data.other_shipper_ticket_code === null
          ? []
          : data.other_shipper_ticket_code.map((item: any) => item.ticket_code),
      sttBookedForName: data.stt_booked_for_name,
      sttForwardRate: 0,
      totalTariff: totalTariff,
      totalRate: totalRates,
      isDangerousGoods: data.is_dangerous_goods,
      sttIsRts: IS_STT_RTS(data.last_status_stt_return),
      sttIsRerouteMisroute:
        data.last_status_stt_return.match(/^reroute/gi) &&
        data.last_status_before_stt_return.match(/^mis-route/gi),
      sttIsRerouteMisbooking:
        data.last_status_stt_return.match(/^reroute/gi) &&
        data.last_status_before_stt_return.match(/^misbooking/gi),
      sttCommodityDescription: data.stt_commodity_description ?? "",
      sttGoodsEstimatePrice: data.stt_goods_estimate_price,
      sttPostalCode: data.stt_postal_code,
      isReverseJourneyAfterCancel: data.is_reverse_journey_after_cancel,
      sttGoodsPrice: data.stt_goods_price,
      sttReturnFee: data.stt_return_fee,
      totalTariffCodRetail: totalTariff,
      isReverseJourney: data.detail_stt_reverse_journey,
      sttReverseJourneySttNo:
        data.detail_stt_reverse_journey?.root_reverse_stt_no ?? "",
      sttReverseJourneyShipmentId:
        data.detail_stt_reverse_journey?.root_reverse_stt_shipment_id ?? "",
      sttReverseJourneyCodHandling: !!data.detail_stt_reverse_journey
        ?.cod_handling,
      sttIsPromo: data.stt_is_promo,
      sttDocumentSurchargeRateAfterDiscount:
        data.stt_document_surcharge_rate_after_discount,
      sttCommoditySurchargeRateAfterDiscount:
        data.stt_commodity_surcharge_rate_after_discount,
      sttHeavyweightSurchargeRateAfterDiscount:
        data.stt_heavyweight_surcharge_rate_after_discount,
      sttWoodpackingAfterRateDiscount: data.stt_woodpacking_rate_after_discount,
      sttBookedByCountry: data.stt_booked_by_country,
      sttIsWoodpacking: data.stt_is_woodpacking,
      isHubToHub:
        data.stt_product_type?.toUpperCase() === "JUMBOPACKH2H" || false,
      sttFlag: data.stt_flag,
      campaignSttQuote: data.campaign_stt_quote,
      sttInsuranceRateAfterDiscount: data.stt_insurance_rate_after_discount,
      sttCodFeeAfterDiscount: data.stt_cod_fee_after_discount,
      sttShippingSurchargeRateAfterDiscount:
        data.stt_shipping_surcharge_rate_after_discount,
      sttPublishRateAfterDiscount: data.stt_publish_rate_after_discount,
      sttOriginDistrictRateAfterDiscount:
        data.stt_origin_district_rate_after_discount,
      sttDestinationDistrictRateAfterDiscount:
        data.stt_destination_district_rate_after_discount,
      isPriorityTier: data.is_priority
    });
  }

  public convertShipmentIdDataFromApi(res: AxiosResponse<any>): ShipmentIdList {
    const { data } = res;
    const shipmentIdList: ShipmentId[] = [];
    if (data.data.length === 0) {
      return new ShipmentIdList({
        pagination: new Pagination(data.meta.page, data.meta.limit),
        data: []
      });
    } else {
      data.data.map((item: any) => {
        const sender = codePhoneCountry(
          item.shipment_sender_phone.replaceAll("+", "")
        );
        const recepient = codePhoneCountry(
          item.shipment_recipient_phone.replaceAll("+", "")
        );

        shipmentIdList.push(
          new ShipmentId({
            shipmentId: item.shipment_shipment_id,
            shipmentClientIdBranch: item.client_id_branch,
            shipmentBookingId: item.shipment_booking_id,
            shipmentAlgoId: item.shipment_algo_id,
            shipmentProduct: item.shipment_product,
            shipmentOriginCity: item.shipment_origin_city,
            shipmentOriginDistrict: item.shipment_origin_district,
            shipmentDestinationCity: item.shipment_destination_city,
            shipmentDestinationDistrict: item.shipment_destination_district,
            shipmentSenderName: item.shipment_sender_name,
            shipmentSenderAddress: removeNewLineWhitespace(
              item.shipment_sender_address,
              " "
            ),
            shipmentSenderPhone: sender.number,
            shipmentSenderPhoneOriginal: item.shipment_sender_phone,
            shipmentSenderPhoneCode: sender.code,
            shipmentSenderPhoneFlag: sender.flag,
            shipmentRecipientName: item.shipment_recipient_name,
            shipmentRecipientNameKeeped: item.shipment_recipient_name,
            shipmentRecipientAddress: removeNewLineWhitespace(
              item.shipment_recipient_address,
              " "
            ),
            shipmentRecipientAddressKeeped: removeNewLineWhitespace(
              item.shipment_recipient_address,
              " "
            ),
            shipmentRecipientPhone: recepient.number,
            shipmentRecipientPhoneKeeped: recepient.number,
            shipmentRecipientPhoneCode: recepient.code,
            shipmentRecipientPhoneFlag: recepient.flag,
            shipmentCommodityId: item.shipment_commodity_id
              ? item.shipment_commodity_id
              : checkEnableCommodity(item.shipment_algo_id).id,
            shipmentCommodityCode: item.shipment_commodity_code
              ? item.shipment_commodity_code
              : checkEnableCommodity(item.shipment_algo_id).code,
            shipmentCommodityName: item.shipment_commodity_name
              ? item.shipment_commodity_name
              : checkEnableCommodity(item.shipment_algo_id).name,
            shipmentCommodityHsCode: item.shipment_commodity_hs_code,
            shipmentCommodityDocumentSurcharge:
              item.shipment_commodity_document_surcharge,
            shipmentGoodsStatus: item.shipment_goods_status,
            shipmentInsurance: item.shipment_insurance,
            shipmentGoodsPriceEstimation: item.shipment_goods_price_estimation
              ? item.shipment_goods_price_estimation
              : 0,
            shipmentNpwpNumber: item.shipment_npwp_number,
            shipmentGoodDetail: item.shipment_good_detail || [],
            shipmentGoodDetailKeeped: item.shipment_good_detail || [],
            shipmentIsCod: item.shipment_is_cod,
            shipmentIsDfod: item.shipment_is_dfod,
            shipmentCodHandling: item.cod_handling,
            shipmentAbleToCod: item.shipment_is_cod,
            shipmentIsWoodpacking: item.shipment_is_woodpacking,
            shipmentAbleToWoodpacking: item.shipment_is_woodpacking,
            shipmentPacketId: item.shipment_packet_id,
            shipmentChargeableWeight: item.shipment_chargeable_weight,
            customerName: item.customer_name,
            isAdjustable: item.is_adjustable,
            maxAdjustable: item.max_adjustable,
            STT:
              item.STT === null
                ? null
                : new ShipmentIdSTT({
                    sttId: item.STT.stt_id,
                    sttNo: item.STT.stt_no,
                    sttClientId: item.STT.stt_client_id,
                    sttPosId: item.STT.stt_pos_id,
                    sttTaxNumber: item.STT.stt_tax_number,
                    sttGoodsEstimatePrice: item.STT.stt_goods_estimate_price,
                    sttGoodsStatus: item.STT.stt_goods_status,
                    sttTotalAmount: item.STT.stt_total_amount,
                    sttNoRefExternal: item.STT.stt_no_ref_external,
                    sttSource: item.STT.stt_source,
                    sttOriginCityId: item.STT.stt_origin_city_id,
                    sttDestinationCityId: item.STT.stt_destination_city_id,
                    sttOriginDistrictId: item.STT.stt_origin_district_id,
                    sttDestinationDistrictId:
                      item.STT.stt_destination_district_id,
                    sttSenderName: item.STT.stt_sender_name,
                    sttSenderPhone: item.STT.stt_sender_phone,
                    sttSenderAddress: item.STT.stt_sender_address,
                    sttRecipientName: item.STT.stt_recipient_name,
                    sttRecipientAddress: item.STT.stt_recipient_address,
                    sttRecipientPhone: item.STT.stt_recipient_phone,
                    sttProductType: item.STT.stt_product_type,
                    sttOriginDistrictRate: item.STT.stt_origin_district_rate,
                    sttDestinationDistrictRate:
                      item.STT.stt_destination_district_rate,
                    sttPublishRate: item.STT.stt_publish_rate,
                    sttShippingSurchargeRate:
                      item.STT.stt_shipping_surcharge_rate,
                    sttDocumentSurchargeRate:
                      item.STT.stt_document_surcharge_rate,
                    sttCommoditySurchargeRate:
                      item.STT.stt_commodity_surcharge_rate,
                    sttHeavyweightSurchargeRate:
                      item.STT.stt_heavyweight_surcharge_rate,
                    sttBmTaxRate: item.STT.stt_bm_tax_rate,
                    sttPpnTaxRate: item.STT.stt_ppn_tax_rate,
                    sttPphTaxRate: item.STT.stt_pph_tax_rate,
                    sttGrossWeight: item.STT.stt_gross_weight,
                    sttVolumeWeight: item.STT.stt_volume_weight,
                    sttChargeableWeight: item.STT.stt_chargeable_weight,
                    sttCommodityCode: item.STT.stt_commodity_code,
                    sttInsuranceType: item.STT.stt_insurance_type,
                    sttTotalPiece: item.STT.stt_total_piece,
                    sttWarningStatus: item.STT.stt_warning_status,
                    sttCounter: item.STT.stt_counter,
                    sttLastStatusId: item.STT.stt_last_status_id,
                    sttClientSttId: item.STT.stt_client_stt_id,
                    sttVendorSttId: item.STT.stt_vendor_stt_id,
                    sttBilledTo: item.STT.stt_billed_to,
                    sttCodAmount: item.STT.stt_cod_amount,
                    sttIsCod: item.STT.stt_is_cod,
                    sttIsDo: item.STT.stt_is_do,
                    sttMeta: item.STT.stt_meta,
                    sttBookedAt: item.STT.stt_booked_at,
                    sttBookedName: item.STT.stt_booked_name,
                    sttBookedBy: item.STT.stt_booked_by,
                    sttCreatedAt: item.STT.stt_created_at,
                    sttCreatedName: item.STT.stt_created_name,
                    sttCreatedBy: item.STT.stt_created_by,
                    sttUpdatedAt: item.STT.stt_updated_at,
                    sttUpdatedBy: item.STT.stt_updated_by,
                    sttUpdatedName: item.STT.stt_updated_name,
                    shipmentAlgoId: item.STT.shipment_algo_id,
                    sttPieces: item.STT.SttPieces || []
                  }),
            isDO: item.is_do,
            piecePerPack: item.stt_piece_per_pack,
            nextCommodity: item.stt_next_commodity,
            codAmount: item.shipment_cod_amount,
            codFee: item.shipment_cod_fee,
            sttIdInstantBooking: item?.STT?.stt_id,
            senderPhoneInstantBooking: item.shipment_sender_phone.replaceAll(
              "+",
              ""
            ),
            recipientPhoneInstantBooking: item.shipment_recipient_phone.replaceAll(
              "+",
              ""
            ),
            sttNoInstantBooking: item?.STT?.stt_no,
            sttAmountInstantbooking: item?.STT?.stt_total_amount,
            isKeepCod: item.shipment_is_cod,
            isKeepDfod: item.shipment_is_dfod,
            codDfodValue:
              item.shipment_is_cod && !item.shipment_is_dfod
                ? "cod"
                : item.shipment_is_cod && item.shipment_is_dfod
                ? "dfod"
                : "non-cod",
            discountFavoritePercentage: item.discount_favorite_percentage,
            sttRecipientAddressType: item.shipment_recipient_address_type ?? "",
            uniqueId: new Date().getTime() + Math.random(),
          })
        );
      });
    }
    return new ShipmentIdList({
      pagination: new Pagination(data.meta.page, data.meta.limit),
      data: shipmentIdList
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      sttId: data.data.stt_id,
      isDiscount: data.data.is_discount,
      totalDiscount: data.data.total_discount,
      totalAfterDiscount: data.data.total_after_discount,
      totalBeforeDiscount: data.data.total_before_discount
    });
  }

  public convertCancelDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data
    });
  }

  public convertExistCustomerFromApi(result: AxiosResponse): CustomerExist {
    const {
      data: { data }
    } = result;

    return new CustomerExist(data.customer_id);
  }

  public convertProductDeliveryFromApi(
    result: AxiosResponse<any>
  ): ProductDelivery {
    const { data } = result;

    const productListData: ProductDeliveryData[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        productListData.push(
          new ProductDeliveryData({
            id: item.product_type_id,
            name: item.product_type_code,
            status: item.product_type_status
          })
        );
      });
    }

    const pagination = new Pagination(data.meta.page, data.meta.limit);

    return new ProductDelivery(pagination, productListData);
  }

  public convertInsuranceDeliveryFromApi(
    result: AxiosResponse<any>
  ): InsuranceTypeDelivery {
    const { data } = result;

    const insuranceTypeListData: InsuranceTypeDeliveryData[] = [] || null;
    if (data.data.configurable_insurance.insurance_types.insurances === []) {
      [];
    } else {
      data.data.configurable_insurance.insurance_types.insurances.forEach(
        (item: any) => {
          insuranceTypeListData.push(
            new InsuranceTypeDeliveryData({
              id: item.id,
              name: AccountController.accountData.accountIsForeign
                ? item.name
                : item.name.replace("Insurance", "Asuransi"),
              value: item.type,
              status: data.data.configurable_price_status,
              cost: item.cost,
              commissionPos: item.commission_pos,
              maxProtection: item.price_goods_max
            })
          );
        }
      );
    }

    return new InsuranceTypeDelivery(insuranceTypeListData);
  }

  public convertValidatePhoneFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data
    });
  }

  public convertEstimationServiceLevelAgreement(
    result: AxiosResponse<any>
  ): EstimationServiceLevelAgreement {
    const {
      data: { data }
    } = result;
    return new EstimationServiceLevelAgreement({
      minDate: formatDateWithoutTime(data.min),
      maxDate: formatDateWithoutTime(data.max),
      originalMinDate: data.min,
      originalMaxDate: data.max
    });
  }

  public convertEstimateSlaDetail(
    result: AxiosResponse<any>
  ): EstimationServiceLevelAgreement {
    const {
      data: { data }
    } = result;
    return new EstimationServiceLevelAgreement({
      estimateSlaMin: data.estimate_sla_min,
      estimateSlaMax: data.estimate_sla_max
    });
  }

  public convertPosterProductDataFromApi(
    res: AxiosResponse<any>
  ): PosterProduct[] {
    const { data } = res.data;
    return data.length
      ? data.map((item: any) => {
          return new PosterProduct({
            name: item.product_type.toLowerCase(),
            price: item.total_tariff,
            sla: item.estimate_sla,
            discountAgentPrecentage: item.commission_rate,
            discountAgentNominal: item.booking_commission,
            isEmbargo: item.is_embargo
          });
        })
      : [];
  }

  public convertUploadHydraFileDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data.upload_file
    });
  }

  public convertDeleteHydraFileDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data.deleted_files
    });
  }
}
