import { Hub, HubList } from "@/domain/entities/Hub";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";

export class HubMapper {
    public convertHubListFromApi(res: AxiosResponse<any>): HubList {
        const { data } = res;
        return new HubList({
            pagination: new Pagination(
                data.meta.page,
                data.meta.limit,
                data.meta.total_records
            ),
            data: data.data?.length ?
                data.data.map((item: any) =>
                    new Hub({
                        hubId: item.hub_id,
                        hubName: item.hub_name,
                        hubCode: item.hub_code,
                        consolidator: item.partner?.name,
                        originCity: item.origin_city,
                        status: item.status,
                        hubDistrictCode: item.hub_district_code
                    }))
                : []
        })
    }

    public convertHubListByBaggingGroupFromApi(res: AxiosResponse<any>): Hub[] {
        const { data } = res;
        return data.data?.length? data.data.map((item: any) =>
            new Hub({
                hubId: item.id,
                hubType: item.type,
                hubName: item.name,
                hubCityCode: item.city_code
            }))
        : []
    }
}