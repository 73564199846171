import { AccountController } from "@/app/ui/controllers/AccountController";
import moment from "moment";
import { configDates } from "./modules/Date";
import getUserTimezone from "./GetUserTimezone";

const formatDate = (
  date: any,
  format = "DD MMMM YYYY, HH:mm",
  parseZone = true
) => {
  if (!date) return "-";

  if (parseZone) {
    const locales: string =
      AccountController.accountData.account_type_detail.countryCode;

    const searchGMT = new Date(date).toString().search("GMT");
    const UTC = new Date(date).toString().slice(searchGMT, searchGMT + 8);
    if (UTC === "GMT+0700") {
      return date ? moment(date).format(format) + " WIB" : "-";
    } else if (UTC === "GMT+0800") {
      return date
        ? moment(date).format(format) +
            ` ${configDates(UTC)[locales.toLowerCase()]}`
        : "-";
    } else if (UTC === "GMT+0900") {
      return date ? moment(date).format(format) + " WIT" : "-";
    }
  }

  if (!parseZone) {
    return `${moment.parseZone(date).format(format)} ${getUserTimezone()}`;
  }

  return date;
};
export default formatDate;
