import { guessLanguage } from "guesslanguage";
import jsPDF from "jspdf";

export class JsPdfLanguage {
  text = "";
  product = "";
  jsPdf = new jsPDF();
  constructor(fields?: Partial<JsPdfLanguage>) {
    Object.assign(this, fields);
  }
}

export const DEFAULT_FONT = "Montserrat"

const importFont = async (lang: string): Promise<string> => {
  try {
    const customFonts: Record<string, string> = {
      si: "Sinhala",
      sinhala: "Sinhala",
      farsi: "arabic",
      urdu: "arabic",
      hindi: "notosans",
      kazakh: "notosans",
      greek: "notosans",
      turkish: "notosans",
      vietnamese: "notosans"
    };

    const { downloadFontsJsPdf } = await import(
      `@/app/ui/assets/fonts/jsPDF/${(
        customFonts[lang.toLowerCase()] || lang
      ).toLowerCase()}`
    );

    if (downloadFontsJsPdf) {
      await downloadFontsJsPdf();
    }

    return lang;
  } catch (error) {
    return DEFAULT_FONT;
  }
};

const determineLanguageName = (data: any[]): string => {
  const name = data?.[2] ?? data?.[0] ?? DEFAULT_FONT;
  return name === "unknown" ? DEFAULT_FONT : name;
};

const getLanguageFromText = async (data: JsPdfLanguage): Promise<string> => {
  let languageName = DEFAULT_FONT;
  let listFontNames: string[] = [];

  try {
    const language = await new Promise<any[]>((resolve) =>
      guessLanguage.info(data.text, (value: any) => resolve(value))
    );

    languageName = determineLanguageName(language);

    const availableFonts = data.jsPdf.getFontList();
    listFontNames = Array.from(
      Object.keys(availableFonts).map((font: string) => font)
    );
  } catch (error) {
    console.error("Error determining language:", error);
  }

  if (!listFontNames.includes(languageName)) {
    await importFont(languageName);
  }

  return listFontNames.includes(languageName) ? languageName : DEFAULT_FONT;
};

export const detectLanguage = async (data: JsPdfLanguage): Promise<string> => {
  if (data.product.toUpperCase() !== "INTERPACK") {
    return DEFAULT_FONT;
  } else {
    return await getLanguageFromText(data);
  }
};

export const checkIsInterpack = (product: string) => product.toUpperCase() === "INTERPACK"

export const specialLanguage = (language: string): boolean =>
  !!language.toLowerCase().match(/^chinese$|^japanese$|^korean$/);
