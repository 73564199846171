import { CargoApiRepository } from "@/app/infrastructures/repositories/api/CargoApiRepository";
import {
  EditCargoRequestInteface,
  AppendBagOrSttNumberInterface,
  AddCargoRequestInteface,
  UpdateRetryCargoRequestInterface,
  SearchFlightRequestInterface,
  CheckAvailableRequestInterface
} from "@/data/payload/contracts/CargoRequest";
import {
  AirportDetail,
  AirportEntities,
  CargoAddResponse,
  CargoCommodityList,
  CargoEditResponse,
  CargoEntities,
  CargoProductEntities,
  FlightAvailable,
  ListLastReadCargo,
  SearchCargoOptions,
  SearchFlight
} from "@/domain/entities/Cargo";
import { injectable } from "tsyringe";
import {
  ResponseBagSttDetail,
  ResponsePayload
} from "@/domain/entities/ResponsePayload";
import { CargoListRequest } from "@/data/payload/api/CargoApiRequest";

@injectable()
export class CargoPresenter {
  private repository: CargoApiRepository;

  constructor(repo: CargoApiRepository) {
    this.repository = repo;
  }

  public getCargoList(params: CargoListRequest): Promise<CargoEntities> {
    return this.repository.getCargoList(params);
  }
  public getRetryCargoList(params: CargoListRequest): Promise<CargoEntities> {
    return this.repository.getRetryCargoList(params);
  }
  public getDetailCargo(cargoNo: string) {
    return this.repository.getDetailCargo(cargoNo);
  }
  public getDetailRetryCargo(rcNo: string) {
    return this.repository.getDetailRetryCargo(rcNo);
  }
  public editCargo(
    payload: EditCargoRequestInteface
  ): Promise<CargoEditResponse> {
    return this.repository.editCargo(payload);
  }

  public getCargoProductList(status: string): Promise<CargoProductEntities> {
    return this.repository.getCargoProductList(status);
  }

  public getCargoCommodityList(
    page: number,
    limit: number,
    search: string
  ): Promise<CargoCommodityList> {
    return this.repository.getCargoCommodityList(page, limit, search);
  }

  public appendBagOrSttNumber(
    payload: AppendBagOrSttNumberInterface
  ): Promise<ResponseBagSttDetail> {
    return this.repository.appendBagOrSttNumber(payload);
  }

  public getSearchCargoOptions(
    origin: string,
    destination: string
  ): Promise<SearchCargoOptions> {
    return this.repository.getSearchCargoOptions(origin, destination);
  }

  public getAirportList(
    airportCode: string,
    cityCode: string,
    page: number,
    limit: number,
    isLocation?: boolean,
    search?: string
  ): Promise<AirportEntities> {
    return this.repository.getAirportList(
      airportCode,
      cityCode,
      page,
      limit,
      isLocation,
      search
    );
  }

  public getAirportDetail(airportCode: string): Promise<AirportDetail> {
    return this.repository.getAirportDetail(airportCode);
  }

  public createCargo(
    payload: AddCargoRequestInteface
  ): Promise<CargoAddResponse> {
    return this.repository.createCargo(payload);
  }

  public updateRetryCargo(
    id: number,
    payload: UpdateRetryCargoRequestInterface,
    version = "v1"
  ): Promise<ResponsePayload> {
    return this.repository.updateRetryCargo(id, payload, version);
  }

  public getDownloadData(id: number, type: string): Promise<string> {
    return this.repository.getDownloadReport(id, type);
  }
  public getListLastCargo(): Promise<ListLastReadCargo[]> {
    return this.repository.getLastReadCargo();
  }
  public updateListLastCargo(): Promise<boolean> {
    return this.repository.updateLastReadCargo();
  }
  public getLastIdRetryCargo(params: CargoListRequest): Promise<number> {
    return this.repository.getLastIdRetryCargo(params);
  }
  public getLastIdCargo(params: CargoListRequest): Promise<number> {
    return this.repository.getLastIdCargo(params);
  }
  public searchFlight(payload: SearchFlightRequestInterface): Promise<SearchFlight[]> {
    return this.repository.searchFlight(payload);
  }
  public checkAvailable(
    payload: CheckAvailableRequestInterface
  ): Promise<FlightAvailable> {
    return this.repository.checkAvailable(payload);
  }
}
