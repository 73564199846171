import { configDates } from "./modules/Date";

const getUserTimezone = (): string => {
  const offsetMinutes = new Date().getTimezoneOffset();
  const offsetHours = -offsetMinutes / 60;
  const sign = offsetHours >= 0 ? "+" : "-";
  const absHours = Math.floor(Math.abs(offsetHours));
  const absMinutes = Math.abs(offsetMinutes) % 60;

  const timezone = `${sign}${absHours
    .toString()
    .padStart(2, "0")}:${absMinutes.toString().padStart(2, "0")}`;
  return configDates(timezone).id;
};
export default getUserTimezone;
