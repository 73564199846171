import { firstCapitalize } from "@/app/infrastructures/misc/Utils";
import { CargoDetailStt } from "@/domain/entities/Cargo";
import { FlightDetail } from "@/domain/entities/NGen";
import { Pagination } from "@/domain/entities/Pagination";
import {
  BagOrSttReadyToCargo,
  ReadyToCargo,
  ReadyToCargoListEntities,
  ResponseScanReadyToCargo,
  ResponseUpdateReadyToCargo,
  LogReadyToCargoListEntities,
  LogReadyToCargo
} from "@/domain/entities/ReadyToCargo";
import { AxiosResponse } from "axios";
import startCase from "lodash/startCase";

const keyName: any = {
  list: "config_cargo_flight",
  detail: "rtc_flight_config",
  nextDetail: "next_rtc_flight_config",
  nextConfig: "next_config_cargo_flight"
};

const _mapAirportCodeOrigin = (data: any, key: any) => {
  return (
    data[keyName[key]].cargo_flight_origin ||
    data?.next_config_cargo_flight?.cargo_flight_origin ||
    data?.next_rtc_flight_config?.cargo_flight_origin ||
    ""
  );
};

const _mapAirportCodeDestination = (data: any, key: any) => {
  return (
    data[keyName[key]].cargo_flight_destination ||
    data?.next_config_cargo_flight?.cargo_flight_destination ||
    data?.next_rtc_flight_config?.cargo_flight_destination ||
    ""
  );
};

const getFlightData = (data: any, key: string): FlightDetail[] => {
  const flightsData: FlightDetail[] = [
    new FlightDetail({
      DailyFlightSNo: 0,
      FlightNo: data[keyName[key]].cargo_flight_number,
      flightDayTransit: data[keyName[key]].cargo_flight_day_transit,
      departureHour: data[keyName[key]].cargo_flight_departure_hour,
      arrivalHour: data[keyName[key]].cargo_flight_arrival_hour,
      airportCodeOrigin: _mapAirportCodeOrigin(data, key),
      airportCodeDestination: _mapAirportCodeDestination(data, key),
      origin: `${data.rtc_origin_code} - ${firstCapitalize(
        data.rtc_origin_name?.toLowerCase()
      )}`,
      thresholdTime: data[keyName[key]].cargo_flight_treshold
    })
  ];

  if (data[keyName[key]].cargo_flight_transit?.length) {
    data[keyName[key]].cargo_flight_transit.forEach(
      (transit: any, index: number) => {
        flightsData.push(
          new FlightDetail({
            DailyFlightSNo: index + 1,
            FlightNo: transit.cargo_flight_number,
            flightDayTransit: transit.cargo_flight_day_transit,
            departureHour: transit.cargo_flight_departure_hour || "",
            arrivalHour: transit.cargo_flight_arrival_hour || "",
            airportCodeOrigin: transit.cargo_flight_origin,
            airportCodeDestination: transit.cargo_flight_destination,
            destination:
              index === data[keyName[key]].cargo_flight_transit.length - 1
                ? `${data.rtc_destination_code} - ${firstCapitalize(
                  data.rtc_destination_name?.toLowerCase()
                )}`
                : "",
            thresholdTime: transit.cargo_flight_treshold || 0
          })
        );
      }
    );
  } else {
    flightsData[0].destination = `${data.rtc_destination_code
      } - ${firstCapitalize(data.rtc_destination_name?.toLowerCase())}`;
  }
  return flightsData;
};

const getNextFlightsData = (data: any): FlightDetail[] => {
  const config = data?.next_rtc_flight_config ?? {};
  
  return [
    new FlightDetail({
      DailyFlightSNo: 0,
      FlightNo: config.cargo_flight_number || "",
      flightDayTransit: config.cargo_flight_day_transit || 0,
      departureHour: config.cargo_flight_departure_hour || "",
      arrivalHour: config.cargo_flight_arrival_hour || "",
      airportCodeOrigin: config.cargo_flight_origin || "",
      airportCodeDestination: config.cargo_flight_destination || "",
      thresholdTime: config.cargo_flight_treshold
    })
  ];
}


const mappingStatus = (status: any) => {
  if (status.toLowerCase() === "sudah bisa") {
    return "Bisa Dikargo";
  }
  if (status.toLowerCase() === "sudah siap") {
    return "Harus Dikargo";
  }
  if (status.toLowerCase() === "belum bisa") {
    return "Belum Siap Dikargo";
  }
  return status.toLowerCase();
}

export class ReadyToCargoMapper {
  public convertListDataFromApi(res: AxiosResponse): ReadyToCargoListEntities {
    const { data } = res;

    return new ReadyToCargoListEntities({
      pagination: new Pagination(
        data.Meta.page,
        data.Meta.limit,
        data.Meta.total_records
      ),
      data: data.Data.length
        ? data.Data.map(
            (item: any) =>
              new ReadyToCargo({
                id: item.rtc_id,
                noReadyToCargo: item.rtc_no,
                totalBagging: item.rtc_total_bagging,
                totalPieces: item.rtc_total_piece || 0,
                cargoType: item.rtc_cc_transport.toLowerCase(),
                totalWeight: item.rtc_total_weight,
                originCode: item.rtc_origin_code,
                originName: item.rtc_origin_name.toLowerCase() || "-",
                destinationCode: item.rtc_destination_code,
                destinationName: item.rtc_destination_name.toLowerCase() || "-",
                status: mappingStatus(item.rtc_status),
                createdAt: item.rtc_created_at,
                createdName: item.rtc_created_name,
                updatedAt: item.rtc_updated_at,
                updateName: item.rtc_updated_name,
                hubName: item.rtc_hub_name,
                flightsData:
                  !item.config_cargo_cut_off.ct_time &&
                  item.next_config_cargo_flight?.cargo_flight_departure_hour
                    ? getFlightData(item, "nextConfig")
                    : getFlightData(item, "list"),
                nextConfigCargoFlight:
                  !item.config_cargo_cut_off.ct_time &&
                  item.next_config_cargo_flight?.cargo_flight_departure_hour
                    ? getFlightData(item, "nextConfig")
                    : [new FlightDetail()],
                nextCargoFlightTime: item.next_cargo_flight_time,
                haveConfigCargoFlight:
                  item.rtc_cc_transport.match(/plane/gi) &&
                  !!item.config_cargo_cut_off.ct_time,
                thresholdTime: item.config_cargo_flight.cargo_flight_treshold || 0,
                cutOffTime: item.config_cargo_cut_off.ct_time,
                clientParentName: item.rtc_client_parent_name
              })
          )
        : []
    });
  }

  public convertListLogRTCDataFromApi(res: AxiosResponse): LogReadyToCargoListEntities {
    const { data } = res;

    return new LogReadyToCargoListEntities({
      pagination: new Pagination(
        data.Meta.page,
        data.Meta.limit,
        data.Meta.total_records
      ),
      data: data.Data.length
        ? data.Data.map(
          (item: any) =>
            new LogReadyToCargo({
              id: item.log_rtc_id,
              bagOrSttNo: item.log_rtc_bag_or_stt_no,
              createdBy: item.log_rtc_created_by_name,
              createdAt: item.log_rtc_created_at,
              status: item.log_rtc_status,
              originCityCode: item.log_rtc_origin_city_code,
              originCityName: item.log_rtc_origin_city_name,
              destinationCityCode: item.log_rtc_destination_city_code,
              destinationCityName: item.log_rtc_destination_city_name,
              description: item.log_rtc_description,
            })
        )
        : []
    });
  }

  public convertDetailDataFromApi(res: AxiosResponse): ReadyToCargo {
    const {
      data: { data }
    } = res;

    return new ReadyToCargo({
      id: data.rtc_id,
      noReadyToCargo: data.rtc_no,
      totalBagging: data.rtc_total_bagging,
      totalWeight: data.rtc_total_weight,
      totalPieces: data.rtc_total_piece,
      originCode: data.rtc_origin_code,
      originName: startCase(data.rtc_origin_name.toLowerCase()) || "-",
      cargoType: data.rtc_cc_transport.toLowerCase(),
      commodityCode: data.rtc_cc_comodity_group_code,
      commodityName: data.rtc_cc_comodity_group_name,
      destinationCode: data.rtc_destination_code,
      destinationName:
        startCase(data.rtc_destination_name.toLowerCase()) || "-",
      status:
        data.rtc_status.toLowerCase() === "sudah bisa"
          ? "Bisa Dikargo"
          : "Harus Dikargo",
      productType: data.rtc_cc_product_type,
      detail: data.bag_or_stt.length
        ? data.bag_or_stt.map(
          (key: any) =>
            new CargoDetailStt({
              baggingNumber: key.bag_no,
              sttNumber: key.stt_no,
              sttElexysNumber: key.stt_no_elexys,
              totalPieces: key.stt_total_piece,
              grossWeigth: key.gross_weight,
              volumeWeight: key.volume_weight,
              productType: key.product_type,
              commodity: key.commodity_name,
              destinationCityCode: data.rtc_destination_code,
              destinationCityName: startCase(
                data.rtc_destination_name.toLowerCase()
              ),
              commodityGroupCode: key.commodity_group_code,
              commodityGroupName: key.commodity_group_name,
              regionId: key.region_id,
              regionName: key.region_name,
              bagCustomGrossWeight: key.bag_custom_gross_weight,
              sttNoRefExternal: key.stt_no_ref_external,
              shcCode: key.rtc_shc_code,
              shcName: key.rtc_shc_description
            })
        )
        : [],
      flightsData: getFlightData(data, "detail"),
      nextFlightsData: getNextFlightsData(data),
      cutOffTime: data.rtc_cut_off_time.ct_time,
      thresholdTime: data.rtc_flight_config.cargo_flight_treshold || 0,
      nextCargoFlightTime: data.next_rtc_flight_time
    });
  }

  public convertScanDataFromApi(res: AxiosResponse): ResponseScanReadyToCargo {
    const {
      data: { data }
    } = res;
    return new ResponseScanReadyToCargo({
      isAllowUpdateStatus: data.is_allow_update_status,
      errorMessage: data.error_message,
      sttNo: data.stt_no,
      bagNo: data.bag_no,
      destinationCity: `${data.destination_city_code} - ${data.destination_city_name}`,
      bagOrStt: data.bag_or_stt
        ? data.bag_or_stt.map(
          (bagOrStt: any) =>
            new BagOrSttReadyToCargo({
              bagNo: bagOrStt.bag_no,
              bagVendorNo: bagOrStt.bag_vendor_no,
              sttId: bagOrStt.stt_id,
              sttNo: bagOrStt.stt_no,
              sttTotalPiece: bagOrStt.stt_total_piece,
              grossWeight: bagOrStt.gross_weight,
              volumeWeight: bagOrStt.volume_weight,
              productType: bagOrStt.product_type,
              commodityName: bagOrStt.commodity_name,
              isPaid: bagOrStt.is_paid,
              sttAssesmentStatus: bagOrStt.stt_assessment_status
            })
        )
        : []
    });
  }

  public convertUpdateDataFromApi(
    res: AxiosResponse
  ): ResponseUpdateReadyToCargo {
    const {
      data: { data }
    } = res;
    return new ResponseUpdateReadyToCargo({
      totalSttSuccess: data.total_stt_success,
      totalSttFailed: data.total_stt_failed,
      listSttFailed: data.list_stt_failed?.length
        ? data.list_stt_failed.map((stt: any) => ({
          sttNo: stt.stt_no,
          sttElexysNo: stt.stt_elexys_no,
          error: stt.error
        }))
        : []
    });
  }
}
