import {
  EditCommodityApiRequest,
  RequestListCommodity
} from "@/data/payload/api/CommodityApiRequest";
import { EditCommodityRequestInterface } from "@/data/payload/contracts/CommodityRequest";
import { CommodityMapper } from "@/data/persistences/mappers/CommodityMapper";
import { CommodityRepositoryInteface } from "@/data/persistences/repositories/contracts/CommodityRepositoryInterface";
import { Commodity, CommodityList } from "@/domain/entities/Commodity";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class CommodityApiRepository implements CommodityRepositoryInteface {
  private service: ApiService;
  private mapper: CommodityMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: CommodityMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async editCommodity(
    payload: EditCommodityRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editCommodity(),
      undefined,
      payload as EditCommodityApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }

  public async getDetailCommodity(id: number): Promise<Commodity> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailCommodity(id),
      {}
    );
    return this.mapper.convertDetailCommodityFromApi(resp);
  }

  public async getCommodityList(
    params: RequestListCommodity
  ): Promise<CommodityList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCommodityList(params),
      {}
    );
    return this.mapper.convertCommodityDataFromApi(resp);
  }

  public async getDetailCommodityByCode(code: string): Promise<Commodity> {
    const resp = await this.service.invoke(
      "get",
      `${this.endpoints.editCommodity()}/code/${code}`
    );
    return this.mapper.convertDetailCommodityFromApi(resp);
  }
}
