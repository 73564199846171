import { RequestHubList } from "@/domain/entities/Hub";

export class HubEndpoint {

    getHubList(params: RequestHubList) {
        return `/horde/v1/hub?${params.toQueryString()}`;
      }

    getHubListByBaggingGroup() {
      return `/horde/v1/hub/by-bagging-group`;
    }
}
