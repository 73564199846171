import { CommodityApiRepository } from "@/app/infrastructures/repositories/api/CommodityApiRepository";
import { EditCommodityRequestInterface } from "@/data/payload/contracts/CommodityRequest";
import { Commodity, CommodityList } from "@/domain/entities/Commodity";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";

@injectable()
export class CommodityPresenter {
  private repository: CommodityApiRepository;

  constructor(repo: CommodityApiRepository) {
    this.repository = repo;
  }

  public async editCommodity(
    payload: EditCommodityRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editCommodity(payload);
  }

  public async getCommodityList(
    params: RequestListCommodity
  ): Promise<CommodityList> {
    return await this.repository.getCommodityList(params);
  }

  public async getDetailCommodity(id: number): Promise<Commodity> {
    return await this.repository.getDetailCommodity(id);
  }

  public async getDetailCommodityByCode(code: string): Promise<Commodity> {
    return await this.repository.getDetailCommodityByCode(code);
  }
}
