export default {
  loading: "sedang diproses",
  error: {
    internet: {
      title: "Tidak Terhubung ke Internet",
      message: "Pastikan koneksi internet Anda aktif dan muat ulang"
    }
  },
  receipt: {
    createdAt: "dibuat",
    createdAtV2: "dibuat",
    estimation: "estimasi",
    shipper: "pengirim",
    receiver: "penerima",
    destination: "kota tujuan",
    home: "rumah",
    office: "kantor",
    goodsValue: "good value",
    totalCost: "total biaya",
    shippingCost: "biaya kirim",
    shippingCostV2: "B. Kirim",
    insurance: "asuransi",
    totalWeight: "total berat",
    taxBm: "Pajak/BM",
    others: "lain-lain",
    othersV2: "B. Lainnya",
    downloadApps:
      "Download Aplikasi Lion Parcel melalui Appstore atau Playstore dan lacak paketmu sekarang!",
    toc: "S&K : lionparcel.com/sk",
    goodValue: "good value",
    currencyDisplayGoodValue: "{value} USD",
    goodsPrice: "harga barang",
    returnFee: "biaya retur",
    returnFeeV2: "B. Retur",
    returnCost: "total retur",
    codFee: "Biaya COD",
    dfodFee: "Biaya DFOD",
    returStatement: "*jika terjadi retur",
    senderPage: "Lembar Pengirim",
    terms: "Syarat & ketentuan berlaku.",
    info: "Info lebih lanjut: https://lionparcel.com/sk",
    infoTracking: "Ingin tahu status\npaketmu? Yuk lacak\nsecara real-time!",
    infoTrackingPrn: {
      info1: "Ingin tahu status",
      info2: "paketmu? Yuk lacak",
      info3: "secara real-time!",
      info4: ""
    },
    scanHere: "scan di sini"
  },
  shipmentRates: {
    titleAddressOriginDestination: "Alamat Asal & Tujuan",
    titleShipmentType: "Produk Pengiriman",
    titleCommodityType: "Jenis Komoditas",
    placeholderSelectCommodity: "Ketik atau pilih nama komoditas",
    titleWeight: "Berat/Paket",
    titleDimensionPackage: "Dimensi Paket",
    titleDimensionSuffix: "P x L x T",
    titleItemPrice: "Harga Barang",
    placeholderItemPrice: "e.g 20.000",
    titlePrefixItemPrice: "IDR",
    titleButtonCheckRates: "Cek Tarif",
    titleToggleFilterOpen: "Hide",
    titleToggleFilterClose: "Show",
    errorMaximalWeight: "Maksimal berat",
    titleMenuDownload: "Download Tarif",
    subtitleMenuDownload:
      "Gunakan Download Tarif untuk membandingan antara tarif client dan partner. Sebelum mendownload, pastikan kembali semua data sudah terisi dengan benar dan sesuai.",
    titleRouteName: "Cek Tarif",
    placeholderOrigin: "Ketik atau pilih lokasi awal",
    placeholderDestination: "Ketik atau pilih destinasi",
    defaultOptionProduct: "Semua Produk",
    defaultOptionCommodity: "Semua Komoditas",
    emptyListMessage: "Belum ada data cek tarif yang dapat ditampilkan",
    titleDownloadSuccess: "Download Tarif Berhasil",
    titleNotFoundRates: "Harga tidak ditemukan",
    codYes: "Yes",
    codNo: "No",
    tooltipSLA:
      "SLA ini adalah level kota.<br />Akan ada peningkatan sistem SLA level<br />district atau alamat tujuan",
    detailRates: {
      commodity: "Komoditas",
      product: "Produk",
      shipmentRates: "Total Tariff",
      shipmentTime: "Estimasi Pengiriman Level Kota"
    },
    errorOttopackNotAllProduct:
      "Biaya Pengiriman untuk produk yang Anda pilih tidak termasuk Asuransi",
    errorOttopackAllProduct:
      "Biaya Pengiriman untuk produk OTOPACK150 dan OTOPACK250 tidak termasuk Asuransi",
    errorFullEmbargo:
      "Alamat asal & tujuan yang Anda pilih sedang dalam Embargo.",
    errorPartialEmbargo:
      "Beberapa komoditas pada alamat asal & tujuan yang Anda pilih sedang dalam Embargo.",
    titleAddressOrigin: "Kota Asal",
    titleAddressDestination: "Kota Tujuan"
  },
  modalButtonBackTitle: "Kembali",
  modalButtonTryAgainTitle: "Coba Lagi",
  errorNotFoundMessage: "Cek dan atur ulang filter yang Anda cari.",
  errorNotFoundTitle: "Filter tidak ditemukan",
  errorKeywordCaseNotFoundMessage: "Silahkan cari dengan kata kunci lainnya",
  errorKeywordCaseNotFoundTitle: "Tidak Menemukan Data Transaksi",
  invoice: {
    date: "tanggal",
    shipperName: "nama pengirim",
    shipperPhone: "telepon pengirim",
    shipperAddress: "alamat pengirim",
    conssigneeName: "nama penerima",
    conssigneePhone: "telepon penerima",
    conssigneeAddress: "alamat penerima",
    signatureOfShipper: "tanda tangan pengirim",
    itemDescription: "Item Description/ Komoditi",
    unitValue: "Unit Value/Harga Barang",
    qty: "Quantity/Jumlah",
    totalValue: "Total Value/Total Harga Barang",
    weight: "Weight/Berat",
    note:
      "*Note : Publish rate not include tax fee (Ongkos kirim tidak termasuk biaya pajak)",
    term:
      "I declare all the information contained in this invoice to be true and correct, and i am willing to bear\nany cost if the information is incorrect. (Saya menyatakan semua informasi yang berada di invoice\nini adalah benar dan saya bersedia menanggung biaya jika informasi yang saya berikan salah)"
  },
  placeholderDatePicker: "Pilih Tanggal",
  showFilter: "Lihat",
  hideFilter: "Sembunyikan",
  showingPagination: "Menampilkan",
  cnManifest: {
    filterTypeSttListing: "Tipe STT Listing",
    filterTypeUser: "Tipe User",
    filterPartner: "Nama Client/Partner/Customer",
    filterDestinationCity: "Kota Tujuan",
    placeholderDestinationCity: "Ketik atau pilih kota tujuan",
    placeholderSttNo: "Ketik atau pilih No. STT",
    filterDateStart: "Dimulai Dari",
    filterDateEnd: "Sampai",
    errorInvalidStartDate: "Tanggal mulai tidak boleh lebih dari tanggal akhir",
    errorInvalidDuration: "Periode booking yang dapat dipilih max 14 hari",
    errorInvalidEndDate: "Tanggal akhir tidak boleh kurang dari tanggal mulai",
    emptyListMessage: "Belum ada data cek tarif yang dapat ditampilkan"
  },
  placeholderSTTColumn: "Cari No. STT",
  placeholderCargoColumn: "Cari No. Kargo",
  trackingCargo: {
    trackingSTTTabTitle: "Tracking STT",
    trackingCargoTabTitle: "Tracking Kargo",
    buttonTracking: "Lacak",
    buttonPrintReceipt: "Print Resi",
    buttonShowDetailStt: "Detail",
    sttDetailTitle: "Detail STT",
    titleToggleFilterOpen: "Sembunyikan",
    titleToggleFilterClose: "Selengkapnya",
    sttDetailInfo: {
      detailShipperName: "Nama Pengirim",
      detailRecipientName: "Nama Penerima",
      detailDistrictOriginCity: "Kecamatan, Kota Asal",
      detailDistrictDestinationCity: "Kecamatan, Kota Tujuan"
    },
    columnsSttDetail: {
      colNoSttGenesis: "No. STT Genesis",
      colNoSttElexys: "No. STT Elexys",
      colStatusPayment: "Status Pembayaran",
      colPieces: "Koli",
      colCurrentStatus: "Status Terkini",
      colResponse: "Sanggahan",
      colShipmentType: "Produk Pengiriman",
      colShipmentTime: "Estimasi",
      colChargeableWeight: "Berat Dikenakan Biaya",
      colAssessmentStatus: "Status adjusment"
    },
    sttHistoryTitle: "Riwayat Pengiriman",
    sttHistoryTitleLowerCase: "Riwayat pengiriman",
    columnHistoryStatus: {
      colStatusDate: "Tanggal Status",
      colCurrentStatus: "Status Terkini",
      colPieces: "Koli",
      colLocation: "Lokasi",
      colRemarks: "Keterangan"
    },
    error: {
      errMessageEmptyTitle: "Oops.. detail tracking tidak tersedia",
      errMessageEmpty: "data STT",
      errDataNotFoundTitle: "Data Tidak Ditemukan",
      errDataNotFoundMsg: "Maaf, data yang Anda cari tidak ditemukan.",
      errMessageEmptyCargo: "Data Cargo",
      errUnusedTitle: "STT Belum Digunakan",
      errUnusedMsg: "STT yang Anda cari belum digunakan atau <i>unused</i>."
    },
    sttReturn: "Pengganti",
    sttBefore: "Sebelumnya"
  },
  booking: {
    titleConfirmReject: "Pilih Alasan Pembatalan Booking",
    messageConfirmReject:
      "Nomor STT yang sudah dibatalkan tidak dapat dikembalikan.",
    submitConfirmReject: "Lanjutkan",
    cancelConfirmReject: "Kembali",
    placeholderReason: "Pilih alasan pembatalan",
    titleSuccessReject: "Booking Berhasil Dibatalkan !",
    messageSuccessReject:
      'STT dengan "{sttNumber}" berhasil dibatalkan. Alasan pembatalan akan terlihat pada detail status aktivitas.',
    messageInputNewStt:
      "Pembatalan booking dengan alasan diatas wajib memasukkan nomor STT terbaru.",
    errorInputNewStt: "Nomor STT tidak valid, cek & ubah lagi.",
    errorInputNewSttSame: "STT tidak boleh sama dengan yang di Cancel"
  },
  profile: {
    balanceAvailable: "Saldo Tersedia",
    logout: "Keluar",
    logoutConfirmTitle: "Yakin Ingin Keluar?",
    changePassword: {
      title: "Update New Password?"
    }
  },
  Nama: "{prefix}Nama{suffix}",
  balance: {
    balanceAvailable: "Saldo Tersedia",
    searchPlaceholder: "Cari No. STT",
    downloadButtontitle: "Download Mutasi",
    downloadPopupTitle: "Download Mutasi",
    activityTitle: "Aktifitas",
    activityPlaceholder: "Pilih jenis aktifitas",
    transactionDatePickerTitle: "Tanggal Transaksi",
    emptyMessage: "Oops.. Belum ada data yang dapat ditampilkan",
    tableTitle: {
      genesisTransactionId: "ID Transaksi Genesis",
      type: "Tipe Transaksi",
      activity: "Aktivitas",
      amount: "Jumlah",
      lastBalance: "Saldo Terakhir",
      trancasationDate: "Tanggal Transaksi"
    }
  },
  titleSearchReport: "Pencarian laporan",
  customStatus: {
    isPaid:
      "Terjadi kelebihan berat sehingga status tidak dapat diupdate ke {status}",
    delete:
      "Masukan No. STT atau Scan Barcode/QR Code</br>barang yang ingin dihapus dari daftar {status}.",
    deleteInOutHub: "Scan atau masukan nomor STT/bag/DEM untuk menghapus dari daftar update status.",
    confirmUpdateTitle: "Konfirmasi Pembuatan",
    titleSuccess: "STT Berhasil di Update ke {status}",
    successMessageNewStt:
      "STT {listStt} harus dikirim kembali dengan STT pengembalian <b>{newStt}</b>",
    successMessage: "Status STT berhasil diupdate menjadi {status}.",
    partialMessage:
      "Beberapa status STT berhasil diupdate menjadi {status}, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.",
    failedMessage: "Perubahan status {status} gagal dibuat."
  },
  bagging: {
    placeholderSearch: "Cari No. Bag",
    titleButtonCreate: "Buat Bagging",
    tableTitle: {
      partnerName: "Nama Partner",
      userType: "Tipe User",
      destinationType: "Kota Tujuan",
      baggingDate: "Tanggal Bagging",
      totalPieces: "Total Koli",
      notFoundSTTBagging: "No. STT/Bagging tidak ditemukan",
      weightGross: "Berat Kotor"
    },
    createdBaggingDate: "Tanggal Bagging",
    baggingConfirmationTitle: "Konfirmasi Pembuatan?",
    baggingConfirmationEditTitle: "Konfirmasi Perubahan",
    baggingConfirmationEditSub:
      "Anda yakin ingin menyimpan perubahan detail Bagging dengan No. {bagNo}",
    buttonProcessBagging: "Process",
    editBagging: "Ubah Bagging",
    editBaggingAndPrint: "Ubah Bagging & Print Label",
    successEditBagTitle: "Detail No. Bag {bagNo} berhasil diubah."
  },
  cargo: {
    notFoundCargoType:
      "Maaf, jadwal kargo yang Anda pilih sudah penuh atau tidak tersedia",
    successCreateMessage:
      "Pengiriman kargo dengan nomor<br /><b>“{cargoNo}”</b> berhasil dibuat.",
    successCreateMessageV2:
      "Pengiriman kargo dengan nomor<b> “{cargoNo}”</b><br />berhasil dibuat.",
    partialCreateMessage:
      "Beberapa No. STT berhasil dibuat jadwal pengiriman kargo<br />dengan nomor <b>“{cargoNo}”</b>. Namun terdapat kegagalan<br />pada beberapa STT. Silahkan hapus STT bermasalah untuk<br />melanjutkan pengiriman kargo.",
    confirmCreateCargoTitle: "Konfirmasi Pembuatan?",
    placeholderAdd: "Contoh: no. STT/bagging: 99LP1234567890123/88-22-12345678",
    secondPlaceholderAdd: "Masukkan nomor STT/bagging atau scan barcode",
    confirmSubtitleEditCargo:
      "Pastikan kembali, seluruh detail STT sudah benar dan sesuai.",
    titlePopupEditCargo: "Perubahan Berhasil !",
    subtitlePopupEditCargo:
      "Detail pengiriman kargo dengan nomor<br/><b>“{cargoNo}”</b> berhasil diubah.",
    subtitlePartialEditCargo:
      "Beberapa No. STT dengan nomor kargo <b>“{cargoNo}”</b><br />berhasil diubah jadwal pengiriman kargo. Namun terdapat<br />kegagalan pada beberapa STT. Silahkan download untuk<br/>mengatahui kesalahan."
  },
  Loading: "Memuat",
  report: {
    title: "Laporan",
    searchByName: "Cari laporan",
    cards: {
      titleSttReport: "Laporan STT",
      colPartnerName: "Nama Partner",
      placeholderPartnerName: "Ketik atau pilih pos induk/cabang",
      colCity: "Kota",
      labelLastSyncData: "Terakhir Sync Data",
      descLastSyncData:
        "Refresh halaman untuk memperbarui informasi sync data terakhir",
      colDate: "Tanggal",
      labelAllPOS: "Semua POS",
      labelAllClient: "Semua Klien",
      waitingDownload:
        "Proses pencarian sedang berjalan, silahkan tunggu hingga proses pencarian selesai",
      reportType: "Tipe Laporan",
      reportSearch: "Pencarian laporan",
      generateFailed: "Generate gagal",
      reportTypeData: "Tipe Data",
      sttBookedBy: "STT dibooking oleh"
    }
  },
  optional: "opsional",
  bookingShipment: {
    column: {
      sttRecipientEmail: "Email Penerima",
      sttIdentityNumber: "Nomor ID Penerima",
      sttInterTaxNumber: "Nomor Tax ID Penerima",
      detailShipment: "Detail Pengiriman",
      manualSttNumber: "No. STT manual",
      externalRefNumber: "No. referensi eksternal",
      shipperName: "Nama Pengirim",
      recipientName: "Nama Penerima",
      shipperPhoneNumber: "No. Telepon Pengirim",
      recipientPhoneNumber: "No. Telepon Penerima",
      shipperAddress: "Alamat Pengirim",
      recipientAddress: "Alamat Penerima",
      districtOriginCity: "Kecamatan, Kota Asal",
      districtDestinationCity: "Kecamatan, Kota Tujuan",
      postalCode: "Kode Pos",
      itemDetails: "Detail Barang",
      selectServiceType: "Pilih Jenis Pengiriman",
      commodity: "Komoditas",
      insurance: "Asuransi",
      itemPrice: "Harga Barang",
      crossBorderInformation: "Free Trade Zone",
      taxIdentificationNumber: "No Registrasi Pajak (NPWP)",
      shipmentStatus: "Status Pengiriman",
      additionalInformation: "Informasi Tambahan",
      additionalInterpack: "(Khusus INTERPACK)",
      commodityDetails: "Detail Komoditas",
      logisticsDocumentationPhoto: "Foto Pendukung",
      grossWeight: "Berat Kotor",
      dimensionsPackage: "Dimensi/Paket",
      dimensionWeight: "Berat Volume",
      totalItem: "Total Barang",
      totalGrossWeight: "Total Berat Kotor",
      totalDimensionWeight: "Total Berat Dimensi",
      chargeableWeight: "Berat Dikenakan Biaya",
      home: "Rumah",
      office: "Kantor",
      photo: "Foto",
      paymentMethod: "Metode Pembayaran",
      clientName: "Nama Klien",
      woodPacking: "Packing Kayu",
      piecePerPack: "Total Koli Per Kemasan",
      codFeeOptional: "Tagihan COD",
      protection: "Proteksi",
      shippingProtection: "Proteksi Pengiriman"
    },
    detail: {
      createdFor: "Dibooking Untuk",
      shipperAddress: "Alamat Pengirim",
      shipperPhoneNumber: "No.Telepon Pengirim",
      recipientAddress: "Alamat Penerima",
      recipientPhoneNumber: "No. Telepon Penerima",
      addressType: "Tipe Alamat",
      serviceType: "Produk Pengiriman",
      totalPieces: "Jumlah Barang (Pieces/Koli)",
      totalChargeableWeight: "Total Berat Dikenakan Biaya",
      itemPriceEstimate: "Estimasi Harga Barang",
      shipmentInsurance: "Proteksi pengiriman",
      bookedBy: "Booked By",
      billedTo: "Billed to",
      source: "Source",
      returnDO: "DO Balikan",
      dimensions: "Dimensi (P x L x T)",
      troubledStatus: "Status Bermasalah",
      rejectedDate: "Tanggal Ditolak",
      modifiedDate: "Tanggal Diubah",
      createdDate: "Tanggal Dibuat",
      printReceipt: "Cetak File Resi",
      printThermalReceipt: "Print Thermal Resi",
      printThermalReceiptPDF: "Print Thermal Resi PDF",
      PrintBasic: "Print Basic/Save to PDF"
    },
    action: {
      addItems: "Tambah Barang",
      saveShipperData: "Simpan Data Pengirim",
      saveRecipientData: "Simpan Data Penerima",
      cancelBooking: "Batalkan Booking",
      editBooking: "Ubah Booking",
      buttonQuit: "Keluar",
      buttonStay: "Kembali"
    },
    placeholder: {
      shipperName: "Masukan nama pengirim",
      recipientName: "Masukan nama penerima",
      shipperAddress: "Masukan alamat pengirim",
      recipientAddress: "Masukan alamat penerima",
      districtOriginCity: "Ketik atau pilih alamat asal",
      districtDestinationCity: "Masukkan & pilih kecamatan/kota tujuan",
      postalCode: "Masukan kode pos",
      commodity: "Pilih komoditas",
      itemPrice: "Harga barang minimum Rp10.000",
      taxIdentificationNumber: "00.000.000.0-000.000",
      shipmentStatus: "Pilih status pengiriman yang akan dikirim",
      commodityDetails: "Masukan detail komoditas",
      dimensionsPackage: "P x L x T",
      codFee: "Tagihan COD minimum Rp10.000",
      email: "Masukan email penerima",
      sttIdentityNumber: "Masukan nomor ID penerima",
      sttInterTaxNumber: "Masukan nomor tax ID penerima"
    },
    description: {
      logisticsDocumentationPhotoOne:
        "Foto pendukung dapat berupa foto paket sebelum dipacking, foto paket sesudah dipacking, foto ID (KTP) dan foto Tax Id (NPWP) Penerima.",
      logisticsDocumentationPhotoTwo:
        "Maks. file: 5 MB, Format: jpg, png. Rekomendasi dimensi 2 : 4 (contoh: 200px X 800px)"
    },
    product: {
      tagline: "Pengiriman Internasional",
      sla: "hari kerja",
      descAgent: "Diskon Agen",
      zeroCurrency: "Rp-"
    },
    validate: {
      uploadPhotos: "Harap unggah minimal 3 foto",
      itemPrice: "Harga barang minimal Rp10.000"
    },
    estimation: {
      totalCost: "Total Tarif",
      title: "Detail Pembayaran",
      cityRate: "City Rate",
      forwardRate: "Forward Rate",
      shipmentRates: "Biaya Pengiriman",
      commoditySurcharge: "Biaya Tambahan Komoditas",
      weightAdditionalCharge: "Biaya Tambahan Berat",
      additionalDocumentCost: "Biaya Tambahan Dokumen",
      woodpackingCost: "Biaya Packing Kayu",
      additionalCost: "Biaya Tambahan",
      insurance: "Proteksi",
      shippingProtectionFee: "Proteksi",
      adminCost: "Biaya Admin",
      taxEstimate: "Est. Pajak",
      returnCost: "Biaya Retur",
      protection: "Proteksi pengiriman",
      protectionCost: "Biaya admin proteksi pengiriman"
    },
    payment: {
      title: "Jenis Pembayaran",
      cash: "Tunai"
    },
    bookButton: {
      printLater: "Booking, Print Nanti",
      printThermal: "Booking, Print Thermal Resi",
      printThermalPdf: "Booking, Print Thermal Resi PDF",
      printBasic: "Booking, Print Basic/Save to PDF",
      failedBook: "Booking Gagal !"
    },
    placeholderSelect: {
      selectDistrictOriginCity: "Pilih kecamatan, kota asal",
      SelectDistrictDestinationCity: "Pilih kecamatan, kota tujuan",
      SelectclientPartner: "Pilih client/partner",
      selectDate: "Pilih tanggal"
    },
    list: {
      searchStt: "Search for STT number",
      filter: {
        bookingPeriod: "Periode",
        BookedFor: "Booked For",
        shipperPhoneNumber: "No Telepon Pengirim",
        recipientPhoneNumber: "No Telepon Penerima",
        serviceType: "Produk",
        selectServiceType: "Pilih Produk",
        selectStatus: "Pilih Status",
        status: "Status",
        shipmentInsurance: "Asuransi Pengiriman"
      },
      table: {
        noSTTGenesis: "No. STT Genesis",
        shipmentID: "Shipment ID",
        destinationAddress: "Alamat Tujuan",
        status: "Status",
        bookingDate: "Tanggal Booking",
        totalPieces: "Total Koli",
        totalWeight: "Total Berat",
        serviceType: "Produk",
        commodity: "Komoditas",
        createdFor: "Dibuat Untuk",
        insurance: "Asuransi",
        troubledStatus: "Status Bermasalah",
        clientName: "Nama Klien"
      },
      action: {
        createBooking: "Booking Retail",
        edit: "Ubah Booking",
        thermalReceipt: "Print Thermal Resi",
        thermalPdf: "Print Thermal Resi  PDF",
        basicPdf: "Print Basic/Save to PDF"
      }
    },
    tooltip: {
      manualStt: "Manual STT (Opsional)",
      manualSttMsg: "Isi jika ingin menggunakan STT Manual.",
      insurance: "Biaya proteksi:",
      insuranceMsg: `
          <ul class="custom-list">
            <li>Tier 1: Harga barang Rp1 - Rp1.000.000, <b>biaya proteksi flat Rp950</b></li>
            <li>Tier 2: Harga barang di atas Rp1.000.000 - Rp3.000.000, <b>biaya proteksi flat Rp4.750</b></li>
            <li>Tier 3: Harga barang di atas Rp3.000.000 - Rp5.000.000, <b>biaya proteksi flat Rp8.550</b></li>
            <li>Tier 4: Harga barang di atas Rp5.000.000 - Rp10.000.000, <b>biaya proteksi flat Rp19.000</b></li>
            <li>Tier 5: Harga barang di atas Rp10.000.000 - Rp20.000.000, <b>biaya proteksi flat Rp38.000</b></li>
            <li>Tier 6: Harga barang di atas Rp20.000.000 - Rp1.000.000.000, <b>biaya proteksi flat 0,27% dari harga barang</b></li>
          </ul>`,
      tax: "No. Registrasi Pajak (NPWP)",
      taxMsg:
        "Wajib Pajak bisa individu atau perusahaan sesuai dengan yang sudah terdaftar.",
      shipmentStatus: "Status Pengiriman",
      shipmentStatusMsg:
        "Status pengiriman berlaku untuk pengiriman dari Kota Asal FTZ. Isi dengan kondisi barang yang akan dikirimkan.",
      detailCommodity: "Detail Komoditas",
      detailCommodityMsg:
        "Isi dengan informasi detail dari komoditas pengiriman",
      weight: "Berat Barang (kg)",
      weightMsg:
        "Minimum berat barang adalah 1 kg. Masukan desimal dengan “,” bila dibutuhkan.",
      updateCost: "Perbarui Harga"
    },
    error: {
      noRef: "No. Referensi eksternal wajib diisi",
      minimumRef: "Nomor referensi eksternal minimum 3 angka.",
      senderName: "Nama pengirim wajib diisi",
      recipientName: "'Nama penerima wajib diisi",
      senderNamePhone: "No. Telepon pengirim wajib diisi",
      recipientNamePhone: "No. Telepon penerima wajib diisi",
      invalidNumber: "Nomor telpon tidak valid, mohon ulangi kembali",
      senderAddress: "Alamat pengirim wajib diisi",
      recepientAddress: "Alamat penerima wajib diisi",
      destination: "Kecamatan, Kota Tujuan wajib diisi",
      addressType: "Tipe Alamat wajib diisi",
      postalCode: "Kode Pos wajib diisi",
      commodity: "Komoditas ini tidak aktif",
      mandatoryCommodity: "Komoditas wajib dipilih.",
      itemPrice: "Harga barang wajib diisi",
      detailCommodity: "Detail komoditas wajib diisi",
      serviceType: "Produk pengiriman wajib dipilih",
      embargoStatus: "Kota tujuan pengiriman masih berstatus Embargo",
      sameOriginDestination: "Alamat pengirim dan penerima tidak boleh sama",
      phoneCountryCode: "Masukan code negara dengan benar",
      phoneCharacter: "No. Telepon tidak boleh kurang dari 5 karakter",
      inactiveCity: "Kota dan atau Kecamatan Tidak Aktif",
      inactiveCommodity: "Komoditas tidak aktif",
      commodityNotFound: "Data tidak ditemukan",
      insurance: "Asuransi pengiriman wajib diisi",
      inactiveInsurance: "Asuransi tidak aktif",
      shipmentStatus: "Status pengiriman wajib diisi",
      uploadPhoto:
        "Gagal mengunggah Foto {value} ke storage. Harap hapus dan coba lagi.",
      weightMaximum:
        "Total berat barang melebihi batas maksimum yaitu 400 kg/STT",
      totalPayment: "Total Pembayaran",
      additionalInfoEmailRequired: "Email wajib diisi",
      additionalInfoEmailFormat: "Format email tidak valid",
      sttIdentityNumberRequired: "Nomor identitas penerima wajib diisi",
      sttIdentityNumberMaxLength: "Nomor ID penerima maksimum 100 karakter",
      sttInterTaxNumberRequired: "Nomor registrasi pajak penerima wajib diisi",
      sttInterTaxNumberMaxLength: "Nomor Tax ID penerima maksimum 100 karakter"
    },
    message: {
      updated: "berhasil diubah"
    }
  },
  selecDate: "Pilih tanggal",
  manifest: {
    product: "Produk"
  },
  pickup: {
    confirmTitleCreate: "Konfirmasi Pembuatan",
    confirmTextSuccess: "Process"
  },
  partnerManagement: {
    fileAttachment: "Berkas Pendukung",
    validate: {
      minimumUpload: "Harap unggah minimal 3 file",
      processUpload: "Sedang mengunggah file ke storage",
      processDelete: "Sedang menghapus file dari storage"
    },
    description: {
      fileOne:
        "Berkas pendukung dapat berupa foto/file NPWP, KTP, KK, Dokumen Penutupan POS atau berkas lainnya. ",
      fileTwo:
        "Maks. file: 3 MB, Format: jpg, png, pdf. Rekomendasi dimensi 2 : 4 (contoh: 200px X 800px)"
    },
    label: {
      attachment: "Lampiran"
    },
    error: {
      uploadFile:
        "Gagal mengunggah Lampiran {value} ke storage. Harap hapus dan coba lagi."
    }
  },
  'Process': 'Proses',
  'Region' : 'Wilayah',
  revamp: {
    booking: {
      client: "Klien",
      pos: "POS",
      serviceAvailable: "Anda belum tersedia layanan",
      codOrDfod: "COD atau DFOD",
      contactLP: ", hubungi Admin Lion Parcel.",
      detailSender: "Detail Pengirim",
      detailRecipient: "Detail Penerima",
      detailPackageAndServiceType: "Detail Barang & Jenis Pengiriman",
      itemWeight: "Berat barang",
      insuranceWarning: "Pengiriman tanpa asuransi hanya dapat di klaim sebesar 10x ongkir / maksimal Rp2.000.000",
      deliveryInsurance: "Asuransi Pengiriman",
      detailCommodity: "Detail Komoditas",
      additionalPhoto: "Foto Pendukung",
      beforeAndAfterPackingPhoto: "Foto sebelum & sesudah paket packing",
      packageBeforePacking: "Paket sebelum packing",
      packageAfterPacking: "Paket sesudah packing",
      recipientIdPhoto: "Foto ID Penerima",
      recipientId: "ID Penerima",
      recipientTaxIdPhoto: "Foto Tax ID Penerima",
      bookNow: "Booking sekarang",
      selectSchedule: "Pilih jadwal",
      shippingProtection: "Proteksi pengiriman",
      shippingIsProtected: "Pengiriman sudah terproteksi",
      recipientIdNumber: "Nomor ID penerima",
      recipientTaxIdNumber: "Nomor tax ID penerima"
    }
  }
};
