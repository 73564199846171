/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionCargo = {
  feature_is_gamification: new Rox.Flag(false),
  feature_gamification_message: new Rox.RoxString(""),
  permission_cargo_enable: new Rox.Flag(false),
  permission_cargo_detail_view: new Rox.Flag(false),
  permission_cargo_create: new Rox.Flag(false),
  permission_cargo_detail_edit: new Rox.Flag(false),
  dimension_divider: new Rox.RoxNumber(0),
  config_time_cancel_cargo: new Rox.RoxNumber(2),
  config_interval_auto_refresh_cargo: new Rox.RoxNumber(60),
  config_version_cancel_cargo: new Rox.RoxString("v2"),
  feature_flight_v2_enable: new Rox.Flag(true)
};

export function initFlagsPermissionCargo() {
  // register flags with namespaces for better grouping
  Rox.register("featureCargo", FlagsPermissionCargo);
}
