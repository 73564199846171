import { CargoMapper } from "@/data/persistences/mappers/CargoMapper";
import {
  AirportDetail,
  AirportEntities,
  CargoAddResponse,
  CargoCommodityList,
  CargoData,
  CargoEditResponse,
  CargoEntities,
  CargoProductEntities,
  FlightAvailable,
  ListLastReadCargo,
  SearchCargoOptions,
  SearchFlight
} from "@/domain/entities/Cargo";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { CargoRepositoryInterface } from "@/data/persistences/repositories/contracts/CargoRepositoryInterface";
import {
  EditCargoRequestInteface,
  AppendBagOrSttNumberInterface,
  AddCargoRequestInteface,
  UpdateRetryCargoRequestInterface,
  SearchFlightRequestInterface,
  CheckAvailableRequestInterface
} from "@/data/payload/contracts/CargoRequest";
import {
  EditCargoApiRequest,
  AddBagOrSttNumberRequest,
  AddCargoRequest,
  CargoListRequest,
  UpdateRetryCargoRequest,
  SearchFlightRequest,
  CheckAvailableRequest
} from "@/data/payload/api/CargoApiRequest";
import {
  ResponseBagSttDetail,
  ResponsePayload
} from "@/domain/entities/ResponsePayload";
import { downloadFile } from "../../misc/Utils";

export class CargoApiRepository implements CargoRepositoryInterface {
  private service: ApiService;
  private mapper: CargoMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: CargoMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getCargoList(params: CargoListRequest): Promise<CargoEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListCargo(params),
      {}
    );
    return this.mapper.convertCargoDataFromApi(resp);
  }

  public async getRetryCargoList(
    params: CargoListRequest
  ): Promise<CargoEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListRetryCargo(params),
      {}
    );
    return this.mapper.convertRetryCargoDataFromApi(resp);
  }

  public async getDetailCargo(cargoNo: string): Promise<CargoData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailCargo(cargoNo),
      {}
    );
    return this.mapper.convertCargoDetailFromApi(resp);
  }

  public async getDetailRetryCargo(rcNo: string): Promise<CargoData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailRetryCargo(rcNo),
      {}
    );
    return this.mapper.convertRetryCargoDetailFromApi(resp);
  }

  public async editCargo(
    payload: EditCargoRequestInteface
  ): Promise<CargoEditResponse> {
    const resp = await this.service.invoke(
      "put",
      this.endpoints.editCargo(),
      undefined,
      payload as EditCargoApiRequest
    );
    return this.mapper.convertCargoEditFromApi(resp);
  }

  public async getCargoProductList(
    status: string
  ): Promise<CargoProductEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCargoProductList(status),
      {}
    );
    return this.mapper.convertCargoProductDataFromApi(resp);
  }

  public async getCargoCommodityList(
    page: number,
    limit: number,
    search: string
  ): Promise<CargoCommodityList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getCargoCommodityList(page, limit, search),
      {}
    );
    return this.mapper.convertCargoCommodityFromApi(resp);
  }

  public async appendBagOrSttNumber(
    payload: AppendBagOrSttNumberInterface
  ): Promise<ResponseBagSttDetail> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.appendBagOrSttNumber(),
      undefined,
      payload as AddBagOrSttNumberRequest
    );
    return this.mapper.convertAppendBagOrSttDataFromApi(resp);
  }

  public async getSearchCargoOptions(
    origin: string,
    destination: string
  ): Promise<SearchCargoOptions> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSearchCargoOptions(origin, destination),
      {}
    );
    return this.mapper.convertSearchCargoOptionsFromApi(resp);
  }

  public async getAirportList(
    airportCode: string,
    cityCode: string,
    page: number,
    limit: number,
    isLocation?: boolean,
    search?: string
  ): Promise<AirportEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListAirport(
        airportCode,
        cityCode,
        page,
        limit,
        isLocation,
        search
      ),
      {}
    );
    return this.mapper.convertAirportDataFromApi(resp);
  }

  public async getAirportDetail(airportCode: string): Promise<AirportDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailAirport(airportCode),
      {}
    );
    return this.mapper.convertAirportDetailDataFromApi(resp);
  }

  public async createCargo(
    payload: AddCargoRequestInteface
  ): Promise<CargoAddResponse> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.createCargo(),
      undefined,
      payload as AddCargoRequest
    );
    return this.mapper.convertCargoAddFromApi(resp);
  }

  public async updateRetryCargo(
    id: number,
    payload: UpdateRetryCargoRequestInterface,
    version = "v1"
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "patch",
      this.endpoints.updateRetryCargo(id, version),
      {},
      payload as UpdateRetryCargoRequest
    );
    return this.mapper.convertPayloadData(resp);
  }

  public async getDownloadReport(id: number, type: string): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadManifest(
        `report/download/${type}/${id}`
      )}`,
      type === "epl" ? `EPL_Manifest_${id}` : `cargo_manifest_${id}`,
      ".xlsx",
      true
    );
  }

  public async getLastReadCargo(): Promise<ListLastReadCargo[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getLastReadCargo(),
      {}
    );
    return this.mapper.convertListLastReadCargoFromApi(resp);
  }

  public async updateLastReadCargo(): Promise<boolean> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.updateLastReadCargo(),
      {}
    );
    return true;
  }

  public async getLastIdRetryCargo(params: CargoListRequest): Promise<number> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getLastIdRetryCargo(params),
      {}
    );
    return this.mapper.convertLastIdCargoAndRetryCargo(resp);
  }

  public async getLastIdCargo(params: CargoListRequest): Promise<number> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getLastIdCargo(params),
      {}
    );
    return this.mapper.convertLastIdCargoAndRetryCargo(resp);
  }

  public async searchFlight(payload: SearchFlightRequestInterface): Promise<SearchFlight[]> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.searchFlight(),
      {},
      payload as SearchFlightRequest
    );
    return this.mapper.convertSearchFlightFromApi(resp);
  }

  public async checkAvailable(
    payload: CheckAvailableRequestInterface
  ): Promise<FlightAvailable> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.checkAvailableNGen(),
      {},
      payload as CheckAvailableRequest
    );
    return this.mapper.convertCheckAvailableFromApi(resp);
  }
}
