export default {
  loading: "Your Request is In Progress",
  "Jaringan Internet Terputus": "Oops, Connection was Disconnected",
  "Tidak Terhubung ke Internet": "Oops, Connection was Disconnected",
  "Silahkan periksa jaringan internet Anda":
    "Please check your connection internet",
  "Pastikan koneksi internet Anda aktif dan muat ulang":
    "Please check your connection internet",
  "Data tidak ditemukan pada halaman ini": "No data found on this page",
  "Jangan khawatir ini bukan kesalahan Anda. Terjadi kesalahan pada system, silahkan coba lagi nanti":
    "Don't worry, this is not your fault. An error occurred in the system, please try again later",
  "There was an error in our system, please try again":
    "There was an error in our system, please try again",
  "Apa yang Anda cari tidak ditemukan atau tidak ada.":
    "Oops.. No data to show yet",
  receipt: {
    createdAt: "created",
    createdAtV2: "created at",
    estimation: "estimate",
    shipper: "shipper",
    receiver: "receiver",
    destination: "destination",
    home: "home",
    office: "office",
    goodsValue: "good value",
    totalCost: "total cost",
    shippingCost: "shipment",
    shippingCostV2: "Shipment",
    insurance: "insurance",
    totalWeight: "total weight",
    taxBm: "Tax/BM",
    others: "other",
    othersV2: "Other",
    downloadApps:
      "Download Lion Parcel App via Appstore or Playstore and track your package now!",
    toc: "T&C : lionparcel.com/sk",
    goodValue: "good value",
    currencyDisplayGoodValue: "{value} USD",
    goodsPrice: "goods price",
    returnFee: "return fee",
    returnFeeV2: "Return",
    returnCost: "return cost",
    codFee: "COD Fee",
    dfodFee: "DFOD Fee",
    returStatement: "*if return",
    senderPage: "Scissors for shipper",
    terms: "Terms and Conditions Apply.",
    info: "For more information: https://lionparcel.com/sk",
    infoTracking:
      "Want to know the\nstatus of your\npackage? Track it in\nreal-time!",
    infoTrackingPrn: {
      info1: "Want to know the",
      info2: "status of your package? ",
      info3: "Track it in real-time!",
      info4: ""
    },
    scanHere: "scan here"
  },
  shipmentRates: {
    titleAddressOriginDestination: "District, Origin & Destination City",
    titleShipmentType: "Service Type",
    titleCommodityType: "Commodity Type",
    placeholderSelectCommodity: "Input & select commodity",
    titleWeight: "Weight/Package",
    titleDimensionPackage: "Dimensions/Package",
    titleDimensionSuffix: "L x W x H",
    titleItemPrice: "Item Price",
    placeholderItemPrice: "e.g 6.00",
    titlePrefixItemPrice: "RM",
    titleButtonCheckRates: "Check Rates",
    titleToggleFilterOpen: "Hide",
    titleToggleFilterClose: "Show",
    errorMaximalWeight: "Maximal weight",
    titleMenuDownload: "Download Shipment Rates",
    subtitleMenuDownload:
      "Use Download Shipment Rates to compare client and partner rates. Before downloading, make sure all data is filled in correctly and accordingly.",
    titleRouteName: "Shipment Rates",
    placeholderOrigin: "Input & select origin city",
    placeholderDestination: "Input & select destination city",
    defaultOptionProduct: "All Product",
    defaultOptionCommodity: "All Commodity",
    emptyListMessage: "Oops.. No data to show yet",
    titleDownloadSuccess: "Shipment Rates Download Success",
    titleNotFoundRates: "Shipment Rates Not Found",
    codYes: "Yes",
    codNo: "No",
    tooltipSLA:
      "This SLA is city level.<br/> There will be an upgrade to a district-level </br> SLA system or destination address.",
    detailRates: {
      commodity: "Commodity",
      product: "Service Type",
      shipmentRates: "Total Cost",
      shipmentTime: "City Level Delivery Estimates"
    },
    errorOttopackNotAllProduct:
      "Shipping Costs for your selected product does not include Insurance",
    errorOttopackAllProduct:
      "Shipping Costs for OTOPACK150 and OTOPACK250 products does not include Insurance",
    errorFullEmbargo:
      "The selected origin and/or destination city are currently under Embargo",
    errorPartialEmbargo:
      "Some commodities at the selected origin & destination are currently under Embargo.",
    titleAddressOrigin: "District, Origin",
    titleAddressDestination: "Destination City"
  },

  modalButtonBackTitle: "Back",
  modalButtonTryAgainTitle: "Try Again",
  errorNotFoundMessage: "Oops.. No data to show yet",
  errorNotFoundTitle: "Not Found",
  errorKeywordCaseNotFoundMessage: "Please search with other keywords",
  errorKeywordCaseNotFoundTitle: "Can't Find Transaction Data",
  invoice: {
    date: "date",
    shipperName: "shipper name",
    shipperPhone: "shipper phone",
    shipperAddress: "shipper address",
    conssigneeName: "conssignee name",
    conssigneePhone: "conssignee phone",
    conssigneeAddress: "conssignee address",
    signatureOfShipper: "signature of shipper",
    itemDescription: "Item Description/ Commodity",
    unitValue: "Item Price",
    qty: "Quantity",
    totalValue: "Total Item Prices",
    weight: "Weight",
    note: "*Note : Publish rate not include tax fee",
    term:
      "I declare all the information contained in this invoice to be true and correct, and i am willing to bear\nany cost if the information is incorrect."
  },
  placeholderDatePicker: "Select Date",
  showFilter: "View",
  hideFilter: "Hide",
  showingPagination: "Showing",
  cnManifest: {
    filterTypeSttListing: "STT Listing Type",
    filterTypeUser: "User Type",
    filterPartner: "Client/Partner/Customer Name",
    filterDestinationCity: "Destination City",
    placeholderDestinationCity: "Input & select destination city",
    placeholderSttNo: "Input or Choose STT Number",
    filterDateStart: "Starting From",
    filterDateEnd: "Until",
    errorInvalidStartDate: "Start date cannot be later than the end date",
    errorInvalidDuration: "Periode booking Maximum 14 days booking",
    errorInvalidEndDate: "End date cannot be less than the start date",
    emptyListMessage: "Oops.. No data to show yet"
  },
  placeholderSTTColumn: "Search for STT number",
  placeholderCargoColumn: "Search for Cargo number",
  trackingCargo: {
    trackingSTTTabTitle: "STT Tracking",
    trackingCargoTabTitle: "Cargo Tracking",
    buttonTracking: "Track",
    buttonPrintReceipt: "Print Tracking",
    buttonShowDetailStt: "View STT Details",
    sttDetailTitle: "STT Details",
    titleToggleFilterOpen: "Show",
    titleToggleFilterClose: "Hide",
    sttDetailInfo: {
      detailShipperName: "Shipper Name",
      detailRecipientName: "Recipient Name",
      detailDistrictOriginCity: "District, Origin City",
      detailDistrictDestinationCity: "District, Destination City"
    },
    columnsSttDetail: {
      colNoSttGenesis: "No. STT Genesis",
      colNoSttElexys: "No. STT Elexys",
      colStatusPayment: "Payment Status",
      colPieces: "Pieces",
      colCurrentStatus: "Current Status",
      colResponse: "Response",
      colShipmentType: "Shipment Type",
      colShipmentTime: "Shipment Time",
      colChargeableWeight: "Chargeable Weight"
    },
    sttHistoryTitle: "History Status",
    sttHistoryTitleLowerCase: "History status",
    columnHistoryStatus: {
      colStatusDate: "Status Date",
      colCurrentStatus: "Current Status",
      colPieces: "Pieces",
      colLocation: "Location",
      colRemarks: "Remarks"
    },
    error: {
      errMessageEmptyTitle: "Oops.. No tracking detail to show yet",
      errMessageEmpty: "Track your package easily with the STT number here",
      errMessageEmptyCargo:
        "Track your package easily with the Cargo number here",
      errDataNotFoundTitle: "STT Not Found",
      errDataNotFoundMsg: "Please double-check the STT you input",
      errUnusedTitle: "STT is not yet in use",
      errUnusedMsg:
        "STT you are looking for is not yet in use or <i>unused</i>."
    },
    sttReturn: "Return",
    sttBefore: "Before"
  },
  "Rentang Waktu": "Time Range",
  custom: "custom",
  "hari ini": "today",
  kemarin: "yesterday",
  "3 hari terakhir": "Last 3 Days",
  "7 hari terakhir": "Last 7 Days",
  "14 hari terakhir": "Last 14 Days",
  "30 hari terakhir": "Last 30 Days",
  "31 hari terakhir": "Last 31 Days",
  "satu bulan": "one month",
  "bulan ini": "this month",
  "tahun ini": "this year",
  "Tentukan Tanggal": "Set a Date",
  "Minggu Kemarin": "Last Week",
  "Pilih Range Tanggal": "Select a date range",
  "Cari Manifest ID": "Search manifest ID",
  "Nama Consolidator": "Consolidator Name",
  "Total STT": "Total STT",
  "Total\nKoli": "Total\nPieces",
  "Total Koli": "Total Pieces",
  "Tanggal Dibuat": "Created Date",
  "Dibuat Oleh": "Created By",
  "Print PDF": "Print PDF File",
  "Download Excel": "Download Excel File",
  "Perlu di STI": "Need STI",
  "Nama Partner": "Partner Name",
  "Tanggal STI": "STI Date",
  "No. STT/ID Manifest": "STT Number/Manifest ID",
  "Buat Bagging": "Create Bagging",
  "Ubah Bagging": "Edit Bagging",
  "Ubah Cargo": "Edit Cargo",
  Kargo: "Cargo",
  "Komoditas Kargo": "Cargo Commodity",
  "Masukan NOG": "input NOG",
  "Masukan nomor penerbangan": "Input flight number",
  "Waktu Berangkat": "Departure Time",
  "Waktu Tiba": "Arrival Time",
  "Pilih waktu": "select Time",
  "Maks.": "Max.",
  Dimensi: "Dimension",
  "Ubah Kargo": "Cargo Booking",
  "Ubah & Print PDF": "Booking & Print PDF File",
  "Booking & Print PDF": "Booking & Print PDF File",
  "Ubah & Print Label": "Booking & Print Label",
  "Tidak ada STT atau bagging di dalam kargo ini":
    "No STT or bagging in this cargo",
  "Perubahan Gagal !": "Update Failed !",
  "Pengiriman kargo gagal diubah.": "Cargo status change failed.",
  "Semua STT dari No. Bag yang sama akan terhapus dari daftar pengiriman":
    "All STTs from the same bag number will be deleted from the shipment list",
  "STT Berhasil Dihapus": "Delete STT Success",
  "Anda dapat menambahkan STT baru ke daftar pengiriman":
    "You can add a new STT to your shipment list",
  "No. STT tidak bisa diupdate": "STT number cannot be updated",
  "Total berat kotor aktual": "Total Actual Weight",
  "Total berat volume aktual": "Total Actual Dimension Weight",
  "Total koli aktual": "Total Actual Pieces",
  "Pilih kargo": "Select Cargo",
  "Simpan transportasi": "Save",
  "Booking manual": "Manual Booking",
  "Booking auto": "Auto Booking",
  "Jika memilih booking manual. Anda harus booking terlebih dulu di sistem nGen.":
    "If you select manual booking, you must first book in the nGen system",
  "Nomor Kargo Tidak Ditemukan": "Cargo Number Not Found",
  "Isi Manual": "Booking Manual",
  "Cek Kembali": "Check Again",
  "Booking Kargo Sedang Diproses": "Cargo Booking on Process",
  "Anda akan mendapat notifikasi jika booking kargo berhasil":
    "You will be notified if the cargo booking is successfull.",
  "Cek kembali nomor kargo Anda atau lakukan Booking Kargo secara manual":
    "Double check your cargo number or cargo booking manually",
  "Pastikan nomor cargo telah sesuai, kesalahan pada nomor cargo akan menjadi tanggung jawab konsolidator terkait":
    "Make sure the cargo number is correct, errors in the cargo number will be the responsibility of the relevant consolidator",
  "Pembuatan Berhasil !": "Create Success!",
  "Pembuatan Gagal!": "Create Failed",
  "Pengiriman kargo gagal dibuat.": "Cargo shipment failed to create",
  "Maksimal Koli Pesawat adalah 15 koli": "Maximum Plane Pieces is 15 Pieces",
  "Mohon ubah jumlah koli di dalam kargo atau pilih transportasi kargo lainnya":
    "Please change the number of pieces in the cargo or choose another cargo transport",
  "Transportasi Lain": "Other Transport",
  "Ubah Koli": "Edit Pieces",
  "Yakin Meninggalkan Halaman?": "Are you sure you want to exit?",
  "Perubahan yang Anda tambahkan akan terhapus":
    "The changes you added will be deleted",
  cargo: {
    notFoundCargoType: "Sorry, the cargo schedule is full or unavailable.",
    successCreateMessage:
      "Cargo booking with cargo number <b>{cargoNo}</b> has been made",
    partialCreateMessage:
      "Some STT successfully created a cargo shipment schedule with number <b>“{cargoNo}”</b>. <br/> However, there are failures in some STTs. <br/> Please delete the problematic STT to continue cargo shipment",
    confirmCreateCargoTitle: "Create Cargo?",
    placeholderAdd:
      "Example: STT/Bagging Number: 99LP1234567890123/88-22-12345678",
    secondPlaceholderAdd: "Input STT/Bagging number or scan barcode/QR code",
    confirmSubtitleEditCargo:
      "Save the Cargo detail changes with No. {cargoNo}",
    titlePopupEditCargo: "Cargo Change Saved",
    subtitlePopupEditCargo: "Detail booking cargo No {cargoNo} was changed",
    subtitlePartialEditCargo:
      "Some STT statuses updated with No. Cargo <b>“{cargoNo}”</b><br />, but some failed. Remove problematic STTs to continue update"
  },
  "Pilih Produk": "Select Product",
  "Contoh : No. STT : 99LP1234567890123/88-22-12345678 atau ID Manifest : PUM00000":
    "Example: STT Number : 99LP1234567890123/88-22-12345678 or ID Manifest : PUM00000",
  "Contoh : 99LP1234567890123/88-22-12345678":
    "Example : 99LP1234567890123/88-22-12345678",
  "Masukan No. STT/ID Manifest atau Scan Barcode/QR Code":
    "Input STT number/manifest ID or scan barcode/QR code",
  "*Terjadi kelebihan berat sehingga customers perlu menyelesaikan tagihan kurang bayar":
    "*Overweight occurs {isIdManifest} so customers need to settle underpaid bills",
  "Terjadi kelebihan berat sehingga status tidak dapat diupdate ke Bagging.":
    "There is excess weight so status cannot be updated to Bagging",
  "Terjadi kelebihan berat sehingga status tidak dapat diupdate ke Cargo.":
    "There is excess weight so status cannot be updated to Cargo",
  "Tunggu sampai customers selesai melunasi tagihan kurang bayar":
    "Wait until customer finishes paying the underpayment bill",
  Tambah: "Add",
  "Yakin ingin keluar?": "Are you sure you want to exit?",
  "Yakin Ingin Keluar?": "Are you sure you want to exit?",
  "Pastikan kembali, Anda akan kehilangan semua data jika Anda keluar halaman ini.":
    "Double-check, you will lose all data if you exit this page.",
  "Sebelum keluar, pastikan kembali tidak ada aktivitas yang sedang berjalan.":
    "Before exiting, make sure there is no activity running again",
  "Sebelum Anda keluar pastikan kembali tidak ada proses aktivitas yang berjalan atau semua aktivitas sudah selesai.":
    "Before exiting, make sure there is no activity running again",
  Keluar: "Exit",
  Kembali: "Back",
  "Konfirmasi Pembuatan": "Confirmation of Create",
  "Konfirmasi Perubahan": "Confirmation of Update?",
  "Pastikan kembali, seluruh detail STT sudah benar dan sesuai.":
    "Make sure all STT details are correct",
  "Input ID Manifest Berhasil -": "Success Input - Manifest No.",
  "No. ID Manifest": "Manifest ID",
  "ID Manifest": "Manifest ID",
  "Masukan No. STT atau Scan Barcode/QR Code untuk<br />barang yang ingin dihapus dari daftar Bagging.":
    "Input STT number or Scan Barcode/QR Code for the item</br>you want to delete from the Bagging list.",
  "Hapus Barang": "Delete Confirmation",
  "Masukan No. STT atau Scan Barcode/QR Code</br>untuk barang yang ingin dihapus dari daftar STI.":
    "Input STT Number or Scan Barcode/QR Code for the item</br>you want to delete from the STI list.",
  "Masukan No. STT atau Scan Barcode/QR Code":
    "Input STT number or scan barcode/QR code",
  Terhapus: "Deleted",
  Batal: "Cancel",
  Hapus: "Delete",
  "ID Manifest sudah di input": "ID Manifest has been input",
  "No. STT sudah di input": "STT number has been input",
  "ID Manifest tidak dapat diproses": "Manifest ID cannot be processed",
  "STT No tidak dapat diproses": "STT No cannot be processed",
  "Nomor STT atau Nomor Bagging tidak dapat diproses":
    "STT/Bagging numbers cannot be processed. Check & reset",
  "Hapus STT": "Delete STT?",
  "STT yang Anda hapus akan hilang dari daftar pengiriman":
    "STT you deleted will disappear from the shipment list",
  "STT tidak dapat diproses": "STT cannot be processed",
  "No. STT tidak ditemukan/sudah terhapus":
    "STT number not found or deleted. Check & reset",
  "No. STT/No. Bag tidak ditemukan atau sudah terhapus":
    "STT/Bagging number not found or deleted. Check & reset",
  "Nomor STT atau Nomor Bagging tidak ditemukan":
    "STT/Bagging number not found",
  "Perubahan Status STT Berhasil !": "Update Success!",
  "Status STT berhasil diupdate menjadi STI.":
    "STT Number is updated to <b>“STI”</b>",
  "Tambah ID Manifest Gagal!": "Add Failed",
  "Tambah No. STT Gagal!": "Add Failed",
  "Tambah No. STT Gagal": "Add Failed",
  "Scan ID Manifest Gagal!": "Scan Failed",
  "Scan No. STT Gagal!": "Scan Failed",
  "Scan No. STT Gagal": "Scan Failed",
  "Perubahan Status Gagal !": "Status Change Failed!",
  "Perubahan Berhasil Disimpan !": "Bagging Change Saved",
  "Perubahan status STI gagal dibuat.":
    "STT status has been failed to update to <span class='font-semibold'>“STI”</span>",
  "Pembuatan Bagging Gagal !": "Failed to create Bagging status change.",
  "Bagging gagal dibuat.": "Failed to create Bagging.",
  "Perubahan status Bagging gagal dibuat.": "Bagging status change failed.",
  "STT Tidak dapat ditemukan": "STT not found",
  "Tambah No. Id Manifest Berhasil!": "Successfully added Manifest ID number!",
  "Perubahan Status Berhasil !": "Update Success!",
  "Beberapa STT berhasil ditambahkan, namun terdapat kegagalan pada beberapa STT. STT yang gagal tidak terdapat di dalam list.":
    "Some STT numbers were successfully added, but there were failures with others. The failed STT numbers are not in the list.",
  "Beberapa status STT berhasil diupdate menjadi <b>“STI”</b>, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.":
    "Some STT statuses updated to <b>“STI”</b>, but some failed. Remove problematic STTs to continue update",
  "Beberapa status Bagging berhasil dibuat menjadi Bagging, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.":
    "Some STT statuses updated to “Bagging”, but some failed. Remove problematic STTs to continue update",
  "Beberapa status STT berhasil diupdate menjadi Bagging, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.":
    "Some STT statuses updated to “Bagging”, but some failed. Remove problematic STTs to continue update",
  "Komoditas group tidak sama": "Commodity group is not the same",
  "No. STT tidak sesuai dengan kota tujuan yang dipilih":
    "STT No. does not match the selected destination city",
  "STT tidak sesuai dengan kota tujuan yang dipilih":
    "STT does not match the selected destination city",
  "No. Referensi": "No. Reference",
  "Berat\nKotor": "Gross\nWeight",
  "Berat Kotor": "Gross Weight",
  "Berat\nDimensi": "Dimension\nWeight",
  "Berat Dimensi": "Dimension Weight",
  Wilayah: "Region",
  "Status Terakhir": "Current Status",
  "Input No. STT berhasil -": "Success Input - STT No.",
  "Input No. STT berhasil": "Successful STT Number Input",
  "Kota Asal": "Origin City",
  "Kota Tujuan": "Destination City",
  "Dest.": "Destination",
  Produk: "Service Type",
  Komoditas: "Commodity",
  "Komoditas Group": "Commodity Group",
  "Komoditas Group (Kargo)": "Commodity Group (Cargo)",
  "Komoditas kargo tidak sama": "Cargo commodities are not the same",
  "Pilih komoditas": "Select Commodity",
  "Biaya COD": "COD Cost",
  "No. Bagging": "Bagging Number",
  Catatan: "Remarks",
  Kurir: "Courier",
  "Perbarui Halaman": "Update Page",
  "Terdapat data baru, silahkan perbarui<br />halaman.":
    "New data found, please update the page.",
  Perbarui: "Update",
  "Total Berat Kotor": "Total Gross Weight",
  "Total Berat Barang": "Total Gross Weight",
  "Total Berat Kotor Bag": "Total Bag Gross Weight",
  "Total Berat Dimensi": "Total Dimension Weight",
  "Total Berat Dimensi Bag": "Total Bag Dimension Weight",
  "Total Berat Bag Dikenakan Biaya": "Total Bag Chargeable Weight",
  "Total Berat Volume": "Total Dimension Weight",
  "Total Volume Weight": "Total Dimension Weight",
  "Berat Kotor Bag": "Gross Weight of Pieces",
  "Total Bag Gross Weight": "Total Weight Gross of Pieces",
  "Total Bag Volume Weight": "Total Dimension Weight of Pieces",
  "Bag Volume Weight": "Total Weight Dimension Pieces",
  "Total Bag Chargeable Weight": "Total Chargeable Weight",
  "Bag Chargeable Weight": "Total Chargeable Weight",
  "No. STT tidak dapat dihapus. Minimal barang pengiriman bagging adalah 1 STT.":
    "STT number cannot be deleted. Minimum bagging shipment item is 1 STT.",
  Pembayaran: "Payment",
  "Kurang Bayar": "Unpaid",
  "Sudah Lunas": "Paid",
  "Print Bagging": "Bagging Print",
  "Detail Bagging": "Bagging Detail",
  "Dimensi Bag": "Dimension Pieces",
  "Dimensi wajib diisi": "Dimension Required",
  "Berat kotor bag wajib diisi": "Gross Weight of Pieces Required",
  "Status Bagging": "Bagging Status",
  "(P x L x T)": "(L x W x H)",
  "Berat Volume Bag": "Weight Dimension Bag",
  "Berat Bag Dikenakan Biaya": "Total Chargeable Weight",
  "Pembuatan Bagging Berhasil !": "Create Success!",
  "Bagging berhasil dibuat.": "Bagging was created",
  "Buat Bagging & Print Label": "Create bagging & Print Label",
  "No. STT Sukses": "Success STT",
  "No. STT Gagal": "Failed STT",
  "No. STT": "STT Number",
  Cari: "Search",
  Urutkan: "Sorting",
  Terbaru: "Latest",
  Terlama: "Oldest",
  Menampilkan: "Showing",
  "data dari total": "Data from a total",
  untuk: "of",
  "Data tidak ditemukan": "Data not found",
  "Maaf, data yang Anda cari tidak ditemukan.":
    "Sorry, the <b>“{searchValue}”</b> data you were looking for was not found.",
  Process: "Proceed",
  "Update STI & Print PDF": "Update STI & Print PDF File",
  "Perubahan Status Gagal!": "Update Failed!",
  booking: {
    titleConfirmReject: "Cancellation Confirmation?",
    messageConfirmReject:
      "Select cancellation reason. Canceled STT numbers are non-refundable",
    submitConfirmReject: "Yes, Cancel",
    cancelConfirmReject: "No",
    placeholderReason: "Select cancellation reason",
    titleSuccessReject: "Cancellation Success!",
    messageSuccessReject:
      'Order with STT number "{sttNumber}" canceled successfully. Check detailed status for cancellation reason',
    messageInputNewStt:
      "Cancellation of a booking for the reasons mentioned above must include the latest STT number.",
    errorInputNewStt: "STT number is invalid, check & change again.",
    errorInputNewSttSame: "STT number cannot be the same as the one in Cancel"
  },
  "Cek Tarif": "Shipment Rates",
  Akun: "Account",
  Nama: "{prefix}Name{suffix}",
  "No. Telepon": "Phone Number",
  Ubah: "Edit",
  "Tipe Akun": "Account Type",
  Alamat: "Address",
  "Kode Hub": "Hub Code",
  "Nama Hub": "Hub Name",
  Long: "Longitude",
  Lat: "Latitude",
  "Ubah Password": "Edit Password",
  "Sebelum keluar silahkan pastikan kembali tidak ada aktivitas yang sedang berjalan.":
    "Before exiting, make sure there is no activity running again",
  "Ubah Email": "Edit Email",
  "Email Lama": "Current Email",
  "Email Baru": "New Email",
  "Masukan email baru": "Input new email",
  "Anda yakin ingin menyimpan perubahan detail Akun ?":
    "Are you sure you want to save changes to your Account details ?",
  Simpan: "Save",
  "Perubahan email berhasil !": "You have successfully update email",
  "Perubahan Email Gagal": "Email Change Failed",
  "Password Lama": "Current password",
  "Password Baru": "New password",
  "Masukan password lama": "Input current password",
  "Masukan password baru": "Input new password",
  "Password lama Anda salah": "Input your current password correctly",
  "Password minimal 8 karakter": "Password at least 8 characters",
  "Password harus berisi minimal 8 karakter":
    "Your password must be at least 8 characters",
  "Konfirmasi password baru": "Input confirm new password",
  "Konfirmasi Password Baru": "Confirm New password",
  "Password baru tidak sama":
    "Your new password is not the same. Check and reset",
  "Setelah password berhasil terubah, gunakan password baru untuk Log In.":
    "After the password is successfully changed, use the new password to Log In",
  "Perubahan password berhasil !": "You have successfully update password",
  "Anda yakin ingin menyimpan perubahan password baru ?":
    "You will use a new password when logging in",
  "Ubah Password Melalui Aplikasi": "Change Password Via Application",
  "Silahkan ubah password Anda melalui aplikasi Lion Parcel":
    "Please change your password via the Lion Parcel application",
  Tutup: "Close",
  Ya: "Yes",
  Tidak: "No",
  Laporan: "Report",
  Loading: "Loading",
  profile: {
    balanceAvailable: "Balance",
    logout: "Log Out",
    logoutConfirmTitle: "Confirm Log Out?",
    changePassword: {
      title: "Konfirmasi Perubahan"
    }
  },
  balance: {
    balanceAvailable: "Balance",
    searchPlaceholder: "Search for STT Number",
    downloadButtontitle: "Download Statement",
    downloadPopupTitle: "Download Balance Statement",
    activityTitle: "Activity",
    activityPlaceholder: "Select the activity type",
    transactionDatePickerTitle: "Transaction Date",
    emptyMessage: "Oops.. No saldo detail to show yet",
    tableTitle: {
      genesisTransactionId: "Genesis Transaction ID",
      type: "Type",
      activity: "Activity",
      amount: "Amount",
      lastBalance: "Last Balance",
      trancasationDate: "Transaction Date"
    }
  },
  titleSearchReport: "Search Report",
  report: {
    title: "Report",
    searchByName: "Search report name",
    cards: {
      titleSttReport: "STT Report",
      colPartnerName: "Partner Name",
      placeholderPartnerName: "Type or select a parent/branch POS",
      colCity: "City",
      labelLastSyncData: "Last Sync Data",
      descLastSyncData:
        "Refresh the page to update the latest data sync information",
      colDate: "Date",
      labelAllPOS: "All POS",
      labelAllClient: "All Client",
      waitingDownload:
        "Pencarian laporan STT sedang diproses, harap tunggu sebentar.",
      reportType: "Report Type",
      reportSearch: "Search Report",
      generateFailed: "Generate failed",
      reportTypeData: "Data Type",
      sttBookedBy: "STT Booked By"
    }
  },
  "Pilih Status": "Select status",
  "Semua Status": "All status",
  "Status Terbaru": "Status",
  "Tanggal Diubah": "Modified Date",
  "Nama User": "User Name",
  "Pilih kota tujuan": "Select destination city",
  "Tipe Partner": "Partner Type",
  "Detail Custom Process": "Custom Process Detail",
  "Total Berat Dikenakan Biaya": "Total Chargeable Weight",
  "Berat Dikenakan Biaya": "Chargeable Weight",
  "Berat\nDikenakan Biaya": "Chargeable\nWeight",
  "Print Resi Custom Process": "Print Custom Process Receipts",
  "No. STT Pengganti": "No. STT Replacement",
  "Tipe Konsol": "Consolidator Type",
  "Nama Konsol": "Consolidator Name",
  "3LC": "3LC",
  "Masukan nama konsol": "Input consolidator name",
  "Masukan 3LC": "Input 3LC",
  "Masukan STT pengganti atau catatan lainnya":
    "Input replacement number or add notes.",
  Alasan: "Reason",
  customStatus: {
    isPaid:
      "There was an overload so the status could not be updated to {status}",
    delete:
      "Input STT Number or Scan Barcode/QR Code for the item</br>you want to delete from the {status} list.",
    titleSuccess: "Update Success!",
    confirmUpdateTitle: "Confirm Update Status?",
    successMessageNewStt:
      "STT No. <b>{listStt}</b> successfully updated to {status} with replacement STT No. <b>{newStt}</b>",
    successMessage: "STT Number is updated to <b>“{status}”</b>",
    partialMessage:
      "Some STT statuses were successfully updated to {status}, but there was a failure in some STTs. Please download to find out the error.",
    failedMessage: "Status change {status} failed to create."
  },
  "Status terbaru yang Anda pilih hanya untuk No.STT INTERPACK. Cek & atur ulang":
    "The latest status you select is only for INTERPACK STT Number Check & reset",
  "Status terbaru yang Anda pilih salah. Status harus diperbarui ke <b>“OCC - IMP”</b>. Cek & atur ulang":
    "The latest status you selected is incorrect. The status should be updated to <b>“OCC - IMP”</b>. Check & reset",
  "Status terbaru yang Anda pilih salah. Status harus diperbarui ke <b>“OCC - EXP”</b>. Cek & atur ulang":
    "The latest status you selected is incorrect. The status should be updated to <b>“OCC - EXP”</b>. Check & reset",
  "Buat Booking Baru": "Create Booking",
  "Buat Booking Retail": "Create Booking Retail",
  "Buat Booking Klien": "Create Booking Client",
  "Detail Pengiriman": "Shipment Details",
  "Detail STT": "STT Details",
  optional: "Optional",
  bookingShipment: {
    column: {
      sttRecipientEmail: "Recipient Email",
      sttIdentityNumber: "Recipient ID Number",
      sttInterTaxNumber: "Recipient Tax ID Number",
      detailShipment: "Shipment Details",
      manualSttNumber: "Manual STT Number",
      externalRefNumber: "External Reference Number",
      shipperName: "Shipper Name",
      recipientName: "Recipient Name",
      shipperPhoneNumber: "Shipper Phone Number",
      recipientPhoneNumber: "Recipient Phone Number",
      shipperAddress: "Shipper Address",
      recipientAddress: "Recipient Address",
      districtOriginCity: "District, Origin City",
      districtDestinationCity: "District, Destination City",
      postalCode: "Postal Code",
      itemDetails: "Item Details",
      selectServiceType: "Select Service Type",
      commodity: "Commodity",
      insurance: "Insurance",
      itemPrice: "Item Price",
      crossBorderInformation: "Cross-Border information",
      taxIdentificationNumber: "Tax Identification Number (TIN)",
      shipmentStatus: "Shipment Status",
      additionalInformation: "Additional Information",
      additionalInterpack: "(Only INTERPACK)",
      commodityDetails: "Commodity Details",
      logisticsDocumentationPhoto: "Logistics Documentation Photo",
      grossWeight: "Gross Weight",
      dimensionsPackage: "Dimensions/Package",
      dimensionWeight: "Dimension Weight",
      totalItem: "Total Item",
      totalGrossWeight: "Total Gross Weight",
      totalDimensionWeight: "Total Dimension Weight",
      chargeableWeight: "Chargeable Weight",
      home: "Home",
      office: "Office",
      photo: "Photo",
      paymentMethod: "Payment Method",
      clientName: "Client Name",
      woodPacking: "Woodpacking",
      piecePerPack: "Piece Per Pack",
      codFeeOptional: "Cod Fee",
      protection: "Protection",
      shippingProtection: "Shipping protection"
    },
    detail: {
      createdFor: "Created For",
      shipperAddress: "Address",
      shipperPhoneNumber: "Phone Number",
      recipientAddress: "Address",
      recipientPhoneNumber: "Phone Number",
      addressType: "Address Type",
      serviceType: "Service Type",
      totalPieces: "Total Items (Pieces)",
      totalChargeableWeight: "Total Chargeable Weight",
      itemPriceEstimate: "Item Price Estimate",
      shipmentInsurance: "Shipping Protection",
      bookedBy: "Booked By",
      billedTo: "Billed to",
      source: "Source",
      returnDO: "Return DO",
      dimensions: "Dimensions (L x W x H)",
      troubledStatus: "Troubled Status",
      rejectedDate: "Rejected Date",
      modifiedDate: "Modified Date",
      createdDate: "Created Date",
      printReceipt: "Print Receipt",
      printThermalReceipt: "Print Thermal Receipt",
      printThermalReceiptPDF: "Print Thermal Receipt PDF",
      PrintBasic: "Print Basic/Save to PDF"
    },
    action: {
      addItems: "Add Items",
      saveShipperData: "Save Shipper Data",
      saveRecipientData: "Save Recipient Data",
      cancelBooking: "Cancel Booking",
      editBooking: "Edit Booking",
      buttonQuit: "Quit",
      buttonStay: "Stay Here"
    },
    placeholder: {
      shipperName: "Input shipper name",
      recipientName: "Input recipient name",
      shipperAddress: "Input shipper address",
      recipientAddress: "Input recipient address",
      districtOriginCity: "Input & select district, origin city",
      districtDestinationCity: "Input & select district, destination city",
      postalCode: "Input postal code",
      commodity: "Type and select commodity name",
      itemPrice: "Minimum item price is RM3.2",
      taxIdentificationNumber: "00.00000000000",
      shipmentStatus: "Select shipment status",
      commodityDetails: "Input commodity details",
      dimensionsPackage: "L x W x H",
      codFee: "Minimum cod fee is RM3.2",
      email: "Enter recipient email",
      sttIdentityNumber: "Enter recipient identity number",
      sttInterTaxNumber: "Enter recipient tax ID"
    },
    description: {
      logisticsDocumentationPhotoOne:
        "Supporting photos should include pre-packing, post-packing images of the package, and sender's TIN/ID Card.",
      logisticsDocumentationPhotoTwo:
        "Max file size: 5 MB, Format: jpg, png. Recommended dimensions: 2:4 (e.g., 200px X 800px). Standard logistics requirements"
    },
    product: {
      tagline: "International Shipment",
      sla: "working days",
      descAgent: "Agent Disc",
      zeroCurrency: "RM-"
    },
    validate: {
      uploadPhotos: "Please upload minimal 3 photos",
      itemPrice: "Minimum item price is RM3.2"
    },
    estimation: {
      totalCost: "Total Cost",
      title: "Payment Details",
      cityRate: "City Rate",
      forwardRate: "Forward Rate",
      shipmentRates: "Shipment Rates",
      commoditySurcharge: "Commodity Surcharge",
      weightAdditionalCharge: "Weight Additional Charge",
      additionalDocumentCost: "Additional Document Cost",
      woodpackingCost: "Woodpacking Cost",
      additionalCost: "Additional Cost",
      insurance: "Protection",
      adminCost: "Admin Fee",
      shippingProtectionFee: "Protection",
      taxEstimate: "Tax Estimate",
      returnCost: "Return Cost",
      protection: "Shipping protection"
    },
    payment: {
      title: "Payment Method",
      cash: "Cash"
    },
    bookButton: {
      printLater: "Booking, Print Later ",
      printThermal: "Booking, Print Thermal STT",
      printThermalPdf: "Booking, Print Thermal STT PDF",
      printBasic: "Booking, Print Basic/Save to PDF",
      failedBook: "Booking Failed !"
    },
    placeholderSelect: {
      selectDistrictOriginCity: "Select district, origin city",
      SelectDistrictDestinationCity: "Select district, destination city",
      SelectclientPartner: "Select client/partner",
      selectDate: "Select date"
    },
    list: {
      searchStt: "Search for STT number",
      filter: {
        bookingPeriod: "Period",
        BookedFor: "Booked For",
        shipperPhoneNumber: "Shipper Phone Number",
        recipientPhoneNumber: "Recipient Phone Number",
        serviceType: "Service Type",
        selectServiceType: "Select service type",
        selectStatus: "Select status",
        status: "Status",
        shipmentInsurance: "Shipment Insurance"
      },
      table: {
        noSTTGenesis: "No. STT Genesis",
        shipmentID: "Shipment ID",
        destinationAddress: "Destination Address",
        status: "Status",
        bookingDate: "Booking Date",
        totalPieces: "Total Pieces",
        totalWeight: "Total Weight",
        serviceType: "Service Type",
        commodity: "Commodity",
        createdFor: "Created For",
        insurance: "Insurance",
        troubledStatus: "Troubled Status",
        clientName: "Client Name"
      },
      action: {
        createBooking: "Create Booking",
        edit: "Edit Booking",
        thermalReceipt: "Print Thermal STT",
        thermalPdf: "Print Thermal STT  PDF",
        basicPdf: "Print Basic/Save to PDF"
      }
    },
    tooltip: {
      manualStt: "Manual STT (Optional)",
      manualSttMsg: "Input this field if you want to use manual STT",
      insurance: "",
      insuranceMsg: "Protection fees follow applicable terms and conditions.",
      tax: "Tax Identification Number (TIN)",
      taxMsg:
        "Taxpayers can be individuals or companies according to those who have been registered.",
      shipmentStatus: "Shipment Status",
      shipmentStatusMsg: "Input the condition of the goods",
      detailCommodity: "Commodity Details",
      detailCommodityMsg:
        "Input detailed information about the shipment commodity",
      weight: "Gross Weight (kg)",
      weightMsg:
        "Minimum item weight is 1 kg. Input decimal with “,” if needed",
      updateCost: "Update Cost"
    },
    error: {
      noRef: "External Reference Number is required",
      minimumRef: "External reference number must be at least 3 digits.",
      senderName: "Shipper name is required",
      recipientName: "Recipient name is required",
      senderNamePhone: "Shipper phone number is required",
      recipientNamePhone: "Recipient phone number is required",
      invalidNumber: "Invalid phone number. Check & reset",
      senderAddress: "Shipper address is required",
      recepientAddress: "Recepient address is required",
      destination: "District, destination city is required",
      addressType: "Address type is required",
      postalCode: "Postal code is required",
      commodity: "Commodity is required",
      mandatoryCommodity: "Commodity is required",
      itemPrice: "Item price is required",
      detailCommodity: "Commodity details are required",
      serviceType: "Service type is required",
      embargoStatus: "Destination city of the shipment is still Embargo status",
      sameOriginDestination:
        "Shipper and recipient addresses cannot be the same",
      phoneCountryCode: "Input country code correctly",
      phoneCharacter: "Phone number must not be less than 5 characters",
      inactiveCity: "Inactive City and/or District",
      inactiveCommodity: "Inactive commodity",
      commodityNotFound: "Data not found",
      insurance: "Shipment insurance is required",
      inactiveInsurance: "Inactive insurance",
      shipmentStatus: "Shipment status is required",
      uploadPhoto:
        "Failed to upload Photo {value} to storage. Please delete it and try again.",
      weightMaximum:
        "Total weight of goods exceeds the maximum limit of 400 kg/STT",
      totalPayment: "Total Payment",
      additionalInfoEmailRequired: "Recipient email is required",
      additionalInfoEmailFormat: "Invalid email format",
      sttIdentityNumberRequired: "Recipient identity number is required",
      sttIdentityNumberMaxLength:
        "Recipient ID number maximum length is 100 characters",
      sttInterTaxNumberRequired: "Recipient tax ID is required",
      sttInterTaxNumberMaxLength:
        "Recipient tax ID number maximum length is 100 characters"
    },
    message: {
      updated: "has been updated"
    }
  },
  "Packing Kayu": "Woodpacking",
  "Biaya Packing Kayu": "Woodpacking Charge",
  "Biaya Tambahan": "Additional Charge",
  required: "is required",
  selecDate: "Select date",
  bagging: {
    placeholderSearch: "Serach No. Bag",
    titleButtonCreate: "Create Bagging",
    tableTitle: {
      partnerName: "Partner Name",
      userType: "User Type",
      destinationType: "Destination City",
      baggingDate: "Bagging Date",
      totalPieces: "Total Pieces",
      buttonProcessBagging: "Create",
      notFoundSTTBagging: "STT/Bagging No. not found",
      weightGross: "Weight Gross"
    },
    baggingConfirmationTitle: "Create Bagging?",
    baggingConfirmationEditTitle: "Save Changes?",
    baggingConfirmationEditSub:
      "Save the Bagging detail changes with No. {bagNo}",
    createdBaggingDate: "Created Date",
    editBagging: "Save Bagging",
    editBaggingAndPrint: "Save Bagging & Print Label",
    successEditBagTitle: "Detail Bag No {bagNo} was changed"
  },
  "Tanggal Bagging": "Bagging Date",
  "Tipe User": "User Type",
  "Terjadi Kendala pada proses pengiriman? Hubungi Customer Service 021-80820072\natau hubungi Origin yang bersangkutan.":
    "Problems with the shipment process? Contact Customer Service 021-80820072\nor contact the relevant origin city.",
  Pilih: "Choose",
  "No. Seri Kendaraan/</br>Penerbangan": "No. Vehicle Machine/</br>Flight",
  "Jam Keberangkatan</br>- Kedatangan": "Departure</br>- Arrival Hour",
  "hari": "day",
  "Pilih Tipe Kargo": "Choose Cargo Type",
  Pesawat: "Plane",
  Truk: "Truck",
  Kereta: "Train",
  Kapal: "Ship",
  "Booking Kargo": "Cargo Booking",
  "Proses Kargo": "Cargo Process",
  Periksa: "Check",
  "Masukan No. kargo": "Input cargo number",
  "No. Kargo": "Cargo Number",
  "Total Berat</br>Kotor": "Total Actual Cargo</br>Gross Weight",
  "Total Berat</br>Volume":
    "Total Actual Cargo</br>Dimension Weight",
  "Oops.. Belum ada data yang dapat ditampilkan": "Oops.. No data to show yet",
  OKE: "OK",
  "Buat Pickup": "Create Pick Up",
  "Nama POS": "POS Name",
  "Tanggal Pickup": "Pick Up Date",
  "Nama Vendor/Partner": "Vendor/Partner Name",
  "Nama Kurir": "Courier Name",
  "No. Telp Kurir": "Courier Phone Number",
  "Nama Driver": "Driver Name",
  "No. Telp Driver": "Driver Phone Number",
  "Pos/Pengirim": "Pos/Shipper",
  Tanggal: "Date",
  "Terjadi Kendala Pada Paket Kiriman Anda ? Hubungi Tim Klaim Kami Melalui":
    "Problems with your shipment? Contact our claims team via",
  manifest: {
    product: "Service\nType"
  },
  "S&K": "T&C",
  "Buat Pickup Manifest": "Create a Pick Up Manifest",
  "Pilih atau masukan nama driver": "Select or input a driver name",
  "Masukan nama driver": "Input a driver name",
  "Masukan no. telp driver": "Input a driver phone number",
  "Pilih atau masukan No. seri kendaraan": "Select or input vehicle number",
  "Masukan No. seri kendaraan": "Input vehicle number",
  "Tanggal STT": "STT Date",
  "Masukan No. STT atau Scan Barcode/QR Code untuk<br/>barang yang ingin dihapus dari daftar Pickup.":
    "Input STT Number or Scan Barcode/QR Code for the item</br>you want to delete from the Pickup Manifest list.",
  "Data Kendaraan sudah ada": "Vehicle data already exists",
  "Tambah Driver": "Add Driver",
  "Tambah No. Seri Kendaraan": "Add Vehicle Number",
  "No. Seri": "Vehicle Number",
  Pickup: "Pick Up",
  "Pickup Manifest": "Pick Up Manifest",
  pickup: {
    confirmTitleCreate: "Create Pick Up Manifest?",
    confirmTextSuccess: "Create"
  },
  partnerManagement: {
    fileAttachment: "Berkas Pendukung",
    validate: {
      minimumUpload: "Harap unggah minimal 3 file",
      processUpload: "Sedang mengunggah file ke storage",
      processDelete: "Sedang menghapus file dari storage"
    },
    description: {
      fileOne:
        "Berkas pendukung dapat berupa foto/file NPWP, KTP, KK, Dokumen Penutupan POS atau berkas lainnya. ",
      fileTwo:
        "Maks. file: 3 MB, Format: jpg, png, pdf. Rekomendasi dimensi 2 : 4 (contoh: 200px X 800px)"
    },
    label: {
      attachment: "Lampiran"
    }
  },
  "Pembuatan Pickup Manifest Berhasil !": "Create Success!",
  "Pickup Manifest berhasil dibuat.": "<b>Pick Up manifest</b> was created",
  "Beberapa No. STT berhasil dibuat menjadi Pickup Manifest, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.":
    "Some STT statuses updated to <b>“Pick Up Manifest”</b>, but some failed. Remove problematic STTs to continue update",
  "Pembuatan Pickup Manifest Gagal !": "Create Failed!",
  "Pickup Manifest gagal dibuat.":
    "STT status has been failed to update to <b>“Pick Up Manifest”</b>",
  "Pembuatan Gagal !": "Update Failed!",
  "Pembuatan Booking Baru Berhasil !": "Booking Success",
  "Perubahan Booking Berhasil !": "Update Success!",
  "Tipe Kargo": "Cargo Type",
  "Produk Kargo": "Cargo Product",
  "Tanggal Keberangkatan": "Departure Date",
  "Tanggal Kedatangan": "Arrival Date",
  "Total Berat": "Total Weight",
  "Kode Bandara Tujuan": "Destination Airport Code",
  "No. Penerbangan": "Flight Number",
  "Detail Kargo": "Cargo Details",
  "Total Berat Kotor Kargo": "Total Gross Weight of Cargo",
  "Total Berat Volume Kargo": "Total Weight Dimension of Cargo",
  "Total Berat Kotor Kargo Aktual": "Total Actual Cargo Gross Weight",
  "Total Dimensi": "Total Dimension",
  "Total Berat Volume Kargo Aktual": "Total Actual Cargo Dimension Weight",
  "Total Koli Kargo Aktual": "Total Actual Cargo Pieces",
  "Dibatalkan / Gagal Booking": "Canceled / Failed Booking",
  "Booking Ulang": "Booking Again",
  "Print Resi Kargo": "Print Cargo Receipts",
  "Nomor telepon tidak valid, mohon ulangi kembali":
    "Invalid phone number. Check & reset",
  "Tipe Kota Tujuan": "Destination City District Type",
  "Riwayat Pengiriman": "History Shipment",
  "Total Berat <br /> Kotor": "Total Gross <br /> Weight",
  "Total Berat <br /> Dimensi": "Total Dimension <br /> Weight",
  "Total Berat <br /> Kotor Kargo": "Total Gross <br /> Cargo Weight",
  "Total Berat <br /> Dimensi Kargo": "Total Dimension <br /> Weight of Cargo",
  "Tanggal Status": "Status Date",
  Status: "Current Status",
  "Tanggal Penerbangan": "Flight Date",
  "Estimasi Keberangkatan": "Estimated Departure",
  "Estimasi Kedatangan": "Estimated Arrival",
  "Saldo Anda dibawah limit": "Your Balance is Below Limit!",
  "Saldo dibawah limit": "Balance Below Limit",
  "Saldo Anda mendekati limit": "Balance Close to Limit",
  "Saldo mendekati limit": "Your Balance is Close to Limit!",
  "Lakukan pengisian saldo sekarang juga. Pastikan saldo Anda tersedia sebelum melakukan aktivitas pengiriman.":
    "Top up your balance now. Make sure your balance is available before shipment activities.",
  "Tanggal Booking": "Booking Date",
  Penerima: "Recipient",
  "Alamat Penerima": "Recipient Address",
  "No. Telp Penerima": "Recipient Phone Number",
  "Tipe Komoditas": "Commodity Type",
  NPWP: "TAX IDENTIFICATION NUMBER (TIN)",
  "Harga Barang": "Item Price",
  "Pengenaan Pajak": "Tax Basis",
  "Print Resi Cargo": "Cargo Print",
  "KARGO TRUK": "CARGO TRUCK",
  "KARGO KERETA": "CARGO TRAIN",
  "KARGO KAPAL": "CARGO SHIP",
  "Koli Ke": "Pieces",
  "Aktual Total Berat Kotor Kargo": "Total Actual Cargo Gross Weight",
  "Aktual Total Berat Dimensi Kargo": "Total Actual Cargo Dimension Weight",
  "Terjadi kendala pada proses pengiriman? Hubungi Customer Service 021-80820072 atau hubungi Kota Asal yang bersangkutan.":
    "Problems with the shipment process? Contact Customer Service 021-80820072 or contact the relevant origin city",
  "Yakin Keluar Halaman?": "Are You Sure Want to Quit?",
  "Anda belum selesai mengisi form. Permintaan tidak akan diproses jika Anda keluar dari halaman ini.":
    "You haven't finished filling out the form. The request will not be processed if you quit this page",
  "Pastikan kembali, Anda akan kehilangan semua data jika Anda keluar dari halaman ini.":
    "Make sure again, you will lose all data if you quit this page", 
    "Pilih No. Telepon Penerima" : "Select Consignee Phone Number",
  revamp: {
    booking: {
      client: "Your Client",
      pos: "Your POS",
      serviceAvailable: "currently does not support",
      codOrDfod: "COD or DFOD",
      contactLP: " services, please contact Admin Lion Parcel",
      detailSender: "Sender Details",
      detailRecipient: "Recipient Details",
      detailPackageAndServiceType: "Item Details & Service Type",
      itemWeight: "Item Weight",
      insuranceWarning: "Shipments without insurance can only be claimed up to 10x the shipping fee / maximum to the applicable terms and conditions.",
      deliveryInsurance: "Shipping Insurance",
      detailCommodity: "Commodity Details",
      additionalPhoto: "Additional Photos",
      beforeAndAfterPackingPhoto: "Photos before & after package packing",
      packageBeforePacking: "Package before packing",
      packageAfterPacking: "Package after packing",
      recipientIdPhoto: "Recipient’s ID photo",
      recipientId: "Recipient’s ID",
      recipientTaxIdPhoto: "Recipient’s Tax ID photo",
      bookNow: "Book now",
      selectSchedule: "Choose schedule",
      shippingProtection: "Shipping protection",
      shippingIsProtected: "Shipping is protected",
      recipientIdNumber: "Recipient ID number",
      recipientTaxIdNumber: "Recipient Tax ID number"
    }
  }
};
