import Axios, { AxiosInstance, AxiosResponse, Method } from "axios";
import qs from "qs";
import { injectable } from "tsyringe";
import { Endpoints } from "../misc/Endpoints";
import { getToken, removeAuthCredential } from "../misc/Cookies";
import { ApiServiceInterface } from "@/data/infrastructures/ApiServiceInterface";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import { AuthController } from "@/app/ui/controllers/AuthController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import isAccountBlacklisted from "@/app/infrastructures/misc/common-library/IsAccountBlacklisted";

@injectable()
export default class ApiService implements ApiServiceInterface {
  public client: AxiosInstance;
  public clientPrivate: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      baseURL: Endpoints.baseUrl,
      timeout: 60000
    });
    this.clientPrivate = Axios.create({
      baseURL: Endpoints.baseUrlPrivate,
      timeout: 60000
    });
    if (process.env.NODE_ENV || process.env.VUE_APP_GENESIS_BASE_URL) {
      this.client.interceptors.response.use(
        resp => {
          return resp;
        },
        async error => {
          if (error?.response?.status === 401) {
            await AuthController.onLoggingOut();
          }
          return Promise.reject(error);
        }
      );
      this.client.interceptors.request.use(async config => {
        const currentUsername = AccountController.accountData.username;
        if (isAccountBlacklisted(currentUsername)) {
          await AuthController.onLoggingOut();
          return config;
        }
        if (
          config.url?.includes("/hydra/v2/cargo") &&
          config.method === "post"
        ) {
          config.timeout = 0;
        }
        return config;
      });
    }

    if (process.env.NODE_ENV || process.env.VUE_APP_GENESIS_PRIVATE_API_URL) {
      this.clientPrivate.interceptors.response.use(
        resp => {
          return resp;
        },
        async error => {
          if (error?.response?.status === 401) {
            removeAuthCredential();
            await AuthController.onLoggingOut();
          }
          return Promise.reject(error);
        }
      );
    }
  }

  public async invoke(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = null,
    headers: Map<string, string> = new Map()
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    this.client.defaults.headers["Content-Type"] = "application/json";
    // set custom header if any
    headers.forEach((value: string, key: string) => {
      this.client.defaults.headers.common[key] = value;
    });

    // set auth bearer
    this.client.defaults.headers.Authorization = `Bearer ${getToken()}`;

    // change all services to phoenix for sender
    if (IS_SENDER_ACCOUNT) {
      url = url.replace(/hydra|horde|gober/gi, "phoenix");
    }

    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload ? payload.toJSON() : null,
      method
    });
  }

  public async invokePrivate(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = null,
    headers: Map<string, string> = new Map()
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    this.clientPrivate.defaults.headers["Content-Type"] = "application/json";
    // set custom header if any
    headers.forEach((value: string, key: string) => {
      this.clientPrivate.defaults.headers.common[key] = value;
    });

    // set auth bearer
    this.clientPrivate.defaults.headers.Authorization = `Bearer ${getToken()}`;
    return await this.clientPrivate.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload ? payload.toJSON() : null,
      method
    });
  }

  public async invokePostWithFormData<T>(
    method: Method = "post",
    url = "",
    params: any = {},
    payload: any = null
  ): Promise<AxiosResponse<any>> {
    // set common header
    const result = await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload.toFormData(),
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`
      },
      method
    });
    return result;
  }

  public async invokePrivatePostWithFormData<T>(
    method: Method = "post",
    url = "",
    params: any = {},
    payload: any = null
  ): Promise<AxiosResponse<any>> {
    // set common header
    return await this.clientPrivate.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload.toFormData(),
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`
      },
      method
    });
  }

  public async invokeWoAuth(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = null,
    headers: Map<string, string> = new Map()
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    this.client.defaults.headers["Content-Type"] = "application/json";
    // set custom header if any
    headers.forEach((value: string, key: string) => {
      this.client.defaults.headers.common[key] = value;
    });

    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload ? payload.toJSON() : null,
      method
    });
  }
}
