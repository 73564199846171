import { Pagination } from "./Pagination";
import { DistrictData } from "@/domain/entities/Location";
import { OptionsClass, QueryParamsEntities } from "./MainApp";
import { ProductDeliveryData } from "./Booking";
import { Commodity } from "./Commodity";

export class CustomProcessEntities {
  pagination: Pagination;
  data: CustomProcessData[];

  constructor(pagination: Pagination, data: CustomProcessData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class CustomProcessData {
  id = 0;
  totalStt = "";
  totalPiece = "";
  latestStatus = "";
  createdAt: Date = new Date();
  createdBy = "";
  updatedAt: Date = new Date();
  updatedBy = "";
  partnerName = "";
  partnerCode = "";
  remarks = "";
  constructor(fields?: Partial<CustomProcessData>) {
    Object.assign(this, fields);
  }
}

export class DetailCustomProcess {
  customProcessPartnerName = "";
  customProcessPartnerType = "";
  customProcessCreatedBy = "";
  customProcessCreatedName = "";
  customProcessCreatedAt = "";
  customProcessLatestStatus = "";
  customProcessTotalStt = 0;
  customProcessTotalPiece = 0;
  customProcessTotalGrossWeight = 0;
  customProcessTotalVolumeWeight = 0;
  customProcessTotalChargeableWeight = 0;
  customProcessRemarks = "";
  stt: STT[] = [];
  constructor(fields?: Partial<DetailCustomProcess>) {
    Object.assign(this, fields);
  }
}

export class STT {
  sttId = 0;
  remarks = "";
  sttNo = "";
  sttReplacementNo = "";
  productType = "";
  totalPieces = 0;
  destinationCityId = "";
  destinationCityName = "";
  originCityId = "";
  originCityName = "";
  woodPacking = "";
  commodityCode = "";
  commodityName = "";
  totalGrossWeight = 0;
  totalVolumeWeight = 0;
  chargeableWeight = 0;
  piece: Pieces[] = [];
  sttElexysNo? = "";
  constructor(fields?: Partial<STT>) {
    Object.assign(this, fields);
  }
}

export class Pieces {
  sttPieceId: number;
  grossWeight: number;
  volumeWeight: number;
  sttPieceNo: number;
  constructor(
    sttPieceId: number,
    grossWeight: number,
    volumeWeight: number,
    sttPieceNo: number
  ) {
    this.sttPieceId = sttPieceId;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.sttPieceNo = sttPieceNo;
  }
}

export class SttDetailData {
  sttId: number;
  sttNumber: string;
  product: string;
  totalPieces: number;
  originCityId: string;
  originCity: string;
  destinationCityId: string;
  destinationCity: string;
  woodPacking: string;
  commodityCode: string;
  commodity: string;
  totalGrossWeight: number;
  totalVolumeWeight: number;
  chargeableWeight: number;
  codAmount: number;
  sttLastStatusId: string;
  bookedAt: string;
  piece: PieceDetail[];
  constructor(
    sttId: number,
    sttNumber: string,
    product: string,
    totalPieces: number,
    originCityId: string,
    originCity: string,
    destinationCityId: string,
    destinationCity: string,
    woodPacking: string,
    commodityCode: string,
    commodity: string,
    totalGrossWeight: number,
    totalVolumeWeight: number,
    chargeableWeight: number,
    codAmount: number,
    sttLastStatusId: string,
    bookedAt: string,
    piece: PieceDetail[]
  ) {
    this.sttId = sttId;
    this.sttNumber = sttNumber;
    this.product = product;
    this.totalPieces = totalPieces;
    this.originCityId = originCityId;
    this.originCity = originCity;
    this.destinationCityId = destinationCityId;
    this.destinationCity = destinationCity;
    this.woodPacking = woodPacking;
    this.commodityCode = commodityCode;
    this.commodity = commodity;
    this.totalGrossWeight = totalGrossWeight;
    this.totalVolumeWeight = totalVolumeWeight;
    this.chargeableWeight = chargeableWeight;
    this.codAmount = codAmount;
    this.sttLastStatusId = sttLastStatusId;
    this.bookedAt = bookedAt;
    this.piece = piece;
  }
}

export class PieceDetail {
  sttPieceId: number;
  grossWeight: number;
  volumeWeight: number;
  sttPieceNumber: string;
  lastStatus: string;
  pieceNo: string;
  constructor(
    sttPieceId: number,
    grossWeight: number,
    volumeWeight: number,
    sttPieceNumber: string,
    lastStatus: string,
    pieceNo: string
  ) {
    this.sttPieceId = sttPieceId;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.sttPieceNumber = sttPieceNumber;
    this.lastStatus = lastStatus;
    this.pieceNo = pieceNo;
  }
}

export class SttFailedDetail {
  sttNo = "";
  bagNo = "";
  bagVendorNo = "";
  error = "";
  constructor(fields?: Partial<SttFailedDetail>) {
    Object.assign(this, fields);
  }
}
export class ResponseUpdate {
  customProcessId = 0;
  totalSttSuccess = 0;
  totalSttFailed = 0;
  sttFailed: Array<string | SttFailedDetail> = [];
  newSttNo = "";
  sttId = 0;
  constructor(fields?: Partial<ResponseUpdate>) {
    Object.assign(this, fields);
  }
}

export class CustomProcessSttData {
  isAllowUpdateStatus = false;
  errorMessage = "";
  stt: CustomProcessSttDetail[] = [];
  isPaid = false;
  isOneBagScan = false;
  constructor(fields?: Partial<CustomProcessSttData>) {
    Object.assign(this, fields);
  }
}

export class ReverseDestinationData {
  remarks = "";
  sttProductType = "";
  returnCityCode = "";
  returnCityName = "";
  returnDistrictCode = "";
  returnDistrictName = "";
  returnReceiptAddress = "";
  returnReceiptAddressType = "";
  returnReceiptName = "";
  returnReceiptPhone = "";
  sttDestinationZipCode = "";
  sttCommodityCode = "";
  sttGoodsStatus = "";
  sttTaxNumber = "";
  sttPiecePerPack = 0;
  sttNextCommodity:any = "";

  constructor(fields?: Partial<ReverseDestinationData>) {
    Object.assign(this, fields);
  }
}

export class CustomProcessSttDetail {
  sttId = 0;
  sttNo = "";
  bagNo = "";
  demNo = "";
  sttProductType = "";
  sttTotalPiece = 0;
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttWoodPacking = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttCodAmount = 0;
  sttLastStatusId = "";
  posName = "";
  bookedAt = "";
  piece: CustomProcessPieceDetail[] = [];
  sttElexysNo = "";
  sttReturnLastStatus = "";
  sttReturnCityCode = "";
  sttReturnCityName = "";
  sttReturnDistrictCode = "";
  sttReturnDistrictName = "";
  sttReturnReceiptName = "";
  sttReturnReceiptPhone = "";
  sttReturnReceiptAddress = "";
  sttReturnReceiptAddressType = "";
  isReturnToOriginAddress = false;
  sttNoRefExternal = "";
  isUpdatedByInternal = false;
  reverseDestination = new ReverseDestinationData();
  isOneBagScan = false;

  constructor(fields?: Partial<CustomProcessSttDetail>) {
    Object.assign(this, fields);
  }
}



export class CustomProcessPieceDetail {
  sttPieceId = 0;
  sttPieceGrossWeight = 0;
  sttPieceVolumeWeight = 0;
  sttPieceNo = 0;
  sttPieceLastStatusId = "";
  constructor(fields?: Partial<CustomProcessPieceDetail>) {
    Object.assign(this, fields);
  }
}

export class CustomProcessReason {
  name = "";
  value = "";
  constructor(fields?: Partial<CustomProcessReason>) {
    Object.assign(this, fields);
  }
}

export class CustomProcessReasonList {
  data: CustomProcessReason[] = [];
  constructor(fields?: Partial<CustomProcessReasonList>) {
    Object.assign(this, fields);
  }
}

export class CustomProcessEditAddressForm {
  sttNumber = "";
  shipmentId = "";
  senderName = "";
  consigneeName = "";
  senderAddress = "";
  consigneeAddress = "";
  senderPhone = "";
  originCity: DistrictData | string | any = "";
  destinationCity: DistrictData | string| any = "";
  consigneePhone :any = "";
  sttIsInterpack = false;
  postalCode = "";
  addressType = "";
  product: ProductDeliveryData | string | any = "";
  commodity: Commodity | string | number | any = "";
  goodsStatus: OptionsClass | string = "";
  taxNumber = "";
  isFtz = false;
  isFtzEditable = false;
  sttPiecePerPack = 0;
  sttNextCommodity: string | number = "";
  sttIsCod = "";
  sttIsDfod = false;
  sttIsDo = false;
  sttBookedBy: number | undefined = undefined;
  statusStt = "";
  codHandling = "";
  remarks = "";

  constructor(fields?: Partial<CustomProcessEditAddressForm>) {
    Object.assign(this, fields);
  }

  get isDataFulfilled(): boolean {
    return !!(
      this.senderName &&
      this.consigneeName &&
      this.senderAddress &&
      this.consigneeAddress &&
      this.senderPhone &&
      this.consigneePhone &&
      this.originCity &&
      this.destinationCity &&
      this.addressType
    );
  }
}

export class RequestUpdate {
  customProcessStatus = "";
  sttNo: Array<string> = [];
  remarks = "";
  partnerId: number | undefined = undefined;
  sttBookedBy: number | undefined = undefined;
  sttDestinationDistrictId: string | undefined = undefined;
  sttReceiptName: string | undefined = undefined;
  sttReceiptAddress: string | undefined = undefined;
  sttReceiptPhone: string | undefined = undefined;
  sttReceiptAddressType: string | undefined = undefined;
  sttPiecePerPack = 0;
  sttNextCommodity: string | number = "";
  sttTaxNumber = "";
  sttProductTypeCode = "";
  sttCommodityCode = "";
  sttGoodsStatus = "";
  sttDestinationZipCode = "";
  hubId = "";
  hubName = "";
  hubDistrictCode = "";
  hubOriginCity = "";
  isUpdatedByInternal = false;
  reverseDestination = new ReverseDestinationData();
  hubDestinationId = 0;
  hubDestinationType = "";
  hubDestinationName = "";
  hubDestinationCity = "";
  cityCode = "";

  constructor(fields?: Partial<RequestUpdate>) {
    Object.assign(this, fields);
  }
}

export class StatusPrintdata {
  sttIsRerouteMisbooking = false;
  sttIsRerouteMisroute = false;

  constructor(fields?: Partial<StatusPrintdata>) {
    Object.assign(this, fields);
  }
}

export class RequestPatchReverseDestination {
  checkLastBalance = false;
  totalTariff = 0;
  bookedBy = 0;
  remarks = "";
  sttProductType:any = "";
  returnCityCode = "";
  returnDistrictCode = "";
  returnDistrictName = "";
  returnReceiptAddress = "";
  returnReceiptAddressType = "";
  returnReceiptName = "";
  returnReceiptPhone = "";
  sttDestinationZipCode = "";
  sttCommodityCode:any = "";
  sttGoodsStatus:any = "";
  sttTaxNumber = "";
  constructor(fields?: Partial<RequestPatchReverseDestination>) {
    Object.assign(this, fields);
  }
}

export class STTDetail {
  sttNo = "";
  scanNo = "";
  customProcessStatus = "";
  partnerId= "";
  hubId = "";
  hubName = "";
  hubDistrictCode = "";
  hubOriginCity = "";
  hubDestinationId = 0;
  hubDestinationType = "";
  hubDestinationName = "";
  hubDestinationCity = "";
  cityCode = "";


  constructor(fields?: Partial<STTDetail>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    if (this.hubDestinationId === 0 && this.customProcessStatus === "OUT-HUB") return `${new QueryParamsEntities(this).queryString}&hub_destination_id=0`;
    return new QueryParamsEntities(this).queryString;
  }
}
