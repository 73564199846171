import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  Customer,
  CustomerCommodity,
  CustomerListEntities
} from "@/domain/entities/Customer";
import {
  ResponsePayload
} from "@/domain/entities/ResponsePayload";

export class CustomerMapper {
  public convertListDataFromApi(res: AxiosResponse): CustomerListEntities {
    const { data } = res;

    return new CustomerListEntities({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data?.length
        ? data.data.map(
          (key: any) =>
            new Customer({
                customerId: key.customer_id,
                customerName: key.customer_name,
                customerPhoneNumber: key.customer_phone_number,
                customerAddress: key.customer_address,
                customerAccountType: key.customer_account_type,
                customerRefId: key.customer_ref_id,
                customerDistrictId: key.customer_district_id,
                customerDistrictName: key.customer_district_name,
                customerZipCode: key.customer_zip_code,
                customerAddressType: key.customer_address_type,
                customerCreatedBy: key.customer_created_by,
                customerUpdatedBy: key.customer_updated_by,
                customerCreatedAt: key.customer_created_at,
                customerUpdatedAt: key.customer_updated_at,
                customerEmail: key.customer_email,
                customerNote: key.customer_note,
                customerCommodity: key.customer_commodity,
            })
        )
        : []
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertDetailProductDataFromApi(
    result: AxiosResponse<any>
  ): Customer {
    const { data } = result.data;
    return new Customer({
      customerId: data.customer_id,
      customerName: data.customer_name,
      customerPhoneNumber: data.customer_phone_number,
      customerAddress: data.customer_address,
      customerAccountType: data.customer_account_type,
      customerRefId: data.customer_ref_id,
      customerDistrictId: data.customer_district_id,
      customerDistrictName: data.customer_district_name,
      customerZipCode: data.customer_zip_code,
      customerAddressType: data.customer_address_type,
      customerCreatedBy: data.customer_created_by,
      customerUpdatedBy: data.customer_updated_by,
      customerCreatedAt: data.customer_created_at,
      customerUpdatedAt: data.customer_updated_at,
      customerEmail: data.customer_email,
      customerNote: data.customer_note,
      customerCommodity: new CustomerCommodity({
        code: data.customer_commodity?.commodity_code,
        name: data.customer_commodity?.commodity_name,
        status: data.customer_commodity?.status,
        isDangerousGoods: data.customer_commodity?.is_dangerous_goods,
        isQuarantine: data.customer_commodity?.is_quarantine,
      })
    });
  }
}