import Cookies from "js-cookie";

// TOKEN CREDENTIAL
export const tokenKey = `${process.env.NODE_ENV}_access_token`;
const expiredTokenKey = `${process.env.NODE_ENV}_expired_token`;
const accessKey = `${process.env.NODE_ENV}_access_info`;
const userRolePermission = `${process.env.NODE_ENV}_user_role_permission`;
const agentCode = `${process.env.NODE_ENV}_agent_code`;
const hubData = `${process.env.NODE_ENV}_account_hub_data`;

export const getToken = () => Cookies.get(tokenKey) || "";

export const getExpired = () => Cookies.get(expiredTokenKey);

export const setCredential = ({ token, expired }: any): void => {
  Cookies.set(tokenKey, token);
  Cookies.set(expiredTokenKey, expired);
};

export const setRememberMe = (email: any, password: any) => {
  Cookies.set(accessKey, JSON.stringify({ email, password }));
};

export const getAccessRemember = () => {
  const access = Cookies.get(accessKey);
  const accessInfo = access ? JSON.parse(`${access}`) : {};
  return accessInfo;
};

export const setUserRolePermission = (data: any) => {
  sessionStorage.setItem(userRolePermission, JSON.stringify(data));
};

export const getUserRolePermission = () =>
  sessionStorage.getItem(userRolePermission) || "[]";

export const getAgentCode = () => Cookies.get(agentCode) || "";
export const setAgentCode = (value: string) => Cookies.set(agentCode, value);

// ALGO TOKEN CREDENTIAL

export const tokenKeyAlgo = `${process.env.NODE_ENV}_access_token_algo`;
const expiredTokenKeyAlgo = `${process.env.NODE_ENV}_expired_token_algo`;

export const getTokenAlgo = () => Cookies.get(tokenKeyAlgo) || "";

export const getExpiredAlgo = () => Cookies.get(expiredTokenKeyAlgo);

export const setCredentialAlgo = ({ token, expired }: any): void => {
  Cookies.set(tokenKeyAlgo, token);
  Cookies.set(expiredTokenKeyAlgo, expired);
};

export const removeAuthCredentialAlgo = () => {
  Cookies.remove(tokenKeyAlgo);
  Cookies.remove(expiredTokenKeyAlgo);
};

// MIDDLEWARE TOKEN CREDENTIAL

export const tokenKeyMiddleware = `${process.env.NODE_ENV}_access_token_middleware`;
const expiredTokenKeyMiddleware = `${process.env.NODE_ENV}_expired_token_middleware`;

export const getTokenMiddleware = () => Cookies.get(tokenKeyMiddleware) || "";
export const getExpiredMiddleware = () => Cookies.get(expiredTokenKeyMiddleware);

export const setCredentialMiddleware = ({ token, expired }: any): void => {
  Cookies.set(tokenKeyMiddleware, token);
  Cookies.set(expiredTokenKeyMiddleware, expired);
};
export const removeAuthCredentialMiddleware = () => {
  Cookies.remove(tokenKeyMiddleware);
  Cookies.remove(expiredTokenKeyMiddleware);
};

// NGEN TOKEN CREDENTIAL
export const tokenKeyNGen = `${process.env.NODE_ENV}_access_token_ngen`;

export const getTokenNGen = () => Cookies.get(tokenKeyNGen) || "";

export const setCredentialNGen = ({ token }: any): void => {
  Cookies.set(tokenKeyNGen, token);
};

export const removeAuthCredentialNGen = () => {
  Cookies.remove(tokenKeyNGen);
};

// BANNER
export const banner = `${process.env.NODE_ENV}_access_banner`;

export const getAccessBanner = () => Cookies.get(banner) || "";

export const setCredentialBanner = (value: string): void => {
  Cookies.set(banner, value);
};

export const removeAccessBanner = () => {
  Cookies.remove(banner);
};

// HUB DATA
export const getHubData = (): any =>{ 
  const get = Cookies.get(hubData) || ""
  return get ? JSON.parse(get): {};
};

export const setHubData = (param: any) => Cookies.set(hubData, JSON.stringify(param));

// remove all cookie when log out
export const removeAuthCredential = () => {
  Cookies.remove(tokenKey);
  Cookies.remove(expiredTokenKey);
  sessionStorage.removeItem(userRolePermission);
  Cookies.remove(agentCode);
  removeAuthCredentialAlgo();
  removeAuthCredentialMiddleware();
  removeAuthCredentialNGen();
  removeAccessBanner();
  Cookies.remove(hubData);
};

const isNewpusatResolusi = "checkAccountNewResolusi";
export const setNewPusatResolusi = (item: any) => {
  Cookies.set(isNewpusatResolusi, item);
};

export const getNewPusatResolusi = () => Cookies.get(isNewpusatResolusi) || "";
