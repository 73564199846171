import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import * as Rox from "rox-browser";

const isAccountBlacklisted = (value: string) => {
  Rox.setCustomStringProperty("input email login", function() {
    return value.toLowerCase();
  });
  return FlagsMedusa.config_blacklist_account_genesis.isEnabled();
};
export default isAccountBlacklisted;
