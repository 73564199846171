/* eslint-disable @typescript-eslint/camelcase */

import isAssigned3lc from "@/app/infrastructures/misc/common-library/isAssigned3lc";

export class AccountTypeDetail {
  id = 0;
  partnerReferralCode = "";
  company_name = "";
  phone = "";
  address = "";
  balance = 0;
  created_at = "";
  updated_at = "";
  type = "";
  isDO = false;
  isMixpack = false;
  districtCode = "";
  cityCode = "";
  code = "";
  partnerExternalCode = "";
  parentId = 0;
  firstLogin = "";
  isCodDelivery = false;
  partnerLocation?: any;
  hub?: Hub;
  partnerIdSttReturn = 0;
  posParentId = 0;
  partnerIsCodBooking = false;
  partnerIsCodDelivery = false;
  partnerIsPcu = false;
  countryCode = "";
  partnerPosSaldoDeposit = 0;
  partnerPosTieringLevel = "";
  isCod = false;
  isDfod = false;
  partnerIsAllowBookVippack = false;

  constructor(fields?: Partial<AccountTypeDetail>) {
    Object.assign(this, fields);
  }
}

export class AccountEntities {
  account_id = 0;
  username = "";
  name = "";
  role_type = "";
  role_permission: any = [];
  account_type = "";
  account_type_detail: AccountTypeDetail = new AccountTypeDetail();
  email = "";
  phone_number = "";
  limit_rule_min_balance = 0;
  limit_rule_limit_border = 0;
  account_location?: any;
  account_location_list?: any[];
  featureFlag = [] as FeatureFlagDetail[];
  status = "";
  birthdate = "";
  customerLevel = "";
  assigned3lc = "";

  constructor(fields?: Partial<AccountEntities>) {
    Object.assign(this, fields);
  }

  get isPosAccount(): boolean {
    return (
      this.account_type === "partner" && this.account_type_detail.type === "pos"
    );
  }
  get isPosParentAccount(): boolean {
    return this.isPosAccount && this.account_type_detail.posParentId === 0;
  }
  get isPosChildAccount(): boolean {
    return this.isPosAccount && this.account_type_detail.posParentId > 0;
  }
  get isConsoleAccount(): boolean {
    return (
      this.account_type === "partner" &&
      this.account_type_detail.type === "console"
    );
  }
  get isSubConsoleAccount(): boolean {
    return (
      this.account_type === "partner" &&
      this.account_type_detail.type === "sub-console"
    );
  }
  get isInternalAccount(): boolean {
    return this.account_type === "internal";
  }
  get isAllClient(): boolean {
    return this.account_type === "client";
  }
  get isClientParentAccount(): boolean {
    return this.account_type === "client" && !this.account_type_detail.parentId;
  }
  get isClientBranchAccount(): boolean {
    return (
      this.account_type === "client" && !!this.account_type_detail.parentId
    );
  }
  get isRtsFeatureAvailable(): boolean {
    return !!this.featureFlag.filter(
      (item: FeatureFlagDetail) =>
        item.keyName === "stt-return-rts-rtshq" && item.keyValue
    ).length;
  }
  get isCustomerService(): boolean {
    return (
      this.account_type_detail.type === "customer-service" ||
      this.account_type === "customer-service"
    );
  }
  get normalizedTypeUser(): string {
    switch (this.account_type_detail.type) {
      case "console":
        return "Consolidator";
      case "sub-console":
        return "Sub Consolidator";
      default:
        return this.account_type_detail.type;
    }
  }
  get posPartnerParentId() {
    return this.account_type_detail.posParentId;
  }
  get isAllPartner() {
    return this.account_type === "partner";
  }
  get isPartnerPcu() {
    return (
      this.account_type_detail.partnerLocation?.partner_is_pcu ||
      this.account_type_detail.partnerIsPcu
    );
  }

  get isAdminTransport() {
    return this.isInternalAccount && this.role_type.toLowerCase() === "transport";
  }

  get accountIsForeign() {
    return this.account_type_detail.countryCode?.toLowerCase() !== "id";
  }
  get countryCode() {
    return this.account_type_detail.countryCode?.toLowerCase();
  }

  get isShowAssigned3Lc(): boolean {
    return isAssigned3lc({ accountType: this.account_type, roleName: this.role_type })
  }
}

export class FeatureFlagDetail {
  keyName = "";
  keyValue = false;
  constructor(fields?: Partial<FeatureFlagDetail>) {
    Object.assign(this, fields);
  }
}

export class Hub {
  hub_id?: number;
  hub_name?: string;
  hub_code?: string;
  partner_id?: number;
  constructor(
    hub_id?: number,
    hub_name?: string,
    hub_code?: string,
    partner_id?: number
  ) {
    this.hub_id = hub_id;
    this.hub_name = hub_name;
    this.hub_code = hub_code;
    this.partner_id = partner_id;
  }
}

export class DashboardDeliveryPos {
  totalSttDel: number;
  totalSttDex: number;
  totalSttSti: number;
  totalSttStiDest: number;
  totalSttCargoPlane: number;
  totalSttCargoTrain: number;
  totalSttCargoTruck: number;
  totalSttStiSc: number;
  totalSttStiDestSc: number;
  constructor(
    totalSttDel: number,
    totalSttDex: number,
    totalSttSti: number,
    totalSttStiDest: number,
    totalSttCargoPlane: number,
    totalSttCargoTrain: number,
    totalSttCargoTruck: number,
    totalSttStiSc: number,
    totalSttStiDestSc: number
  ) {
    this.totalSttDel = totalSttDel;
    this.totalSttDex = totalSttDex;
    this.totalSttSti = totalSttSti;
    this.totalSttStiDest = totalSttStiDest;
    this.totalSttCargoPlane = totalSttCargoPlane;
    this.totalSttCargoTrain = totalSttCargoTrain;
    this.totalSttCargoTruck = totalSttCargoTruck;
    this.totalSttStiSc = totalSttStiSc;
    this.totalSttStiDestSc = totalSttStiDestSc;
  }
}

export class ValidationAccountData {
  isAllow = false;
  constructor(fields?: Partial<ValidationAccountData>) {
    Object.assign(this, fields);
  }
}

export class AccountStatus {
  status = false;
  constructor(fields?: Partial<AccountStatus>) {
    Object.assign(this, fields);
  }
}
